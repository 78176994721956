import { NavItemI } from 'app/core/interfaces';

// import { environment } from 'environments/environment';

export const navigation: Array<NavItemI> = [
  // Admin nav items
  {
    name: 'Users',
    url: '/users',
  },
  {
    name: 'RightHolders',
    url: '/right-holders',
  },
  {
    name: 'Partners',
    url: '/partners',
  },
  {
    name: 'Catalogs',
    url: '/catalogs',
  },
  {
    name: 'Works',
    url: '/works',
  },
  {
    name: 'Music Consumers',
    url: '/music-consumers',
  },
  {
    name: 'Claims',
    url: '/claims',
  },
  {
    name: 'Music Usage',
    url: '/music-usage',
  },
  {
    name: 'Liquidations',
    url: '/liquidations',
  },
  {
    name: 'Notification Center',
    url: '/notification-center',
  },
  {
    name: 'Rates',
    url: '/rates',
  },
  {
    name: 'Usage Reports',
    url: '/usage-reports',
    hidden: true,
  },
  {
    name: 'Documentation',
    url: '/documents',
  },
  {
    name: 'Abroad',
    url: '/abroad',
    hidden: true,
  },
  /* {
    name: 'Works',
    url: '/works',
    children: [
      {
        name: 'Repertoire',
        url: '/works/repertoire',
      },
      {
        name: 'Catalogs',
        url: '/works/catalogs',
      },
    ],
  },
  {
    name: 'Home',
    url: '/home',
  },
  {
    name: 'Test',
    url: '/test',
    hidden: !environment.development,
  }, */
];
