/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
// import { Recording } from './recording';
// import { CollectionShare } from './collectionShare';
import { RightHolder, RightHolderDTO } from 'app/shared/models/rightHolder';
import { OwnershipShare, OwnershipShareDTO } from './ownershipShare';
// import { Ticket } from './ticket';
// import { Performer } from './performer';
// import { WorkUsage } from './workUsage';

import { AlternateTitle, AlternateTitleAPI } from './alternateTitle';

export const SOURCES = [
  'radio',
  'tv',
  'Live',
  'Digital',
  'Mechanical',
  'Radio/TV',
  'Background music',
  'TV',
  'Background',
  'Radio',
  'Live Streaming',
];
// export const SOURCETYPES = ['radio-tv', 'background'];
export enum SourceTypes {
  ALL = 'all',
  RADIO_TV = 'radiotv',
  BACKGROUND = 'background',
  BACKGROUND_MUSIC = 'background_music',
  LIVE = 'live',
  DIGITAL = 'digital',
  RADIO = 'radio',
  TV = 'tv',
}

export interface SourceTypeOptionsI {
  value: string;
  title: string;
}

export enum WorkStatus {
  CONFLICT = 'CONFLICT',
  ACCEPTED = 'ACCEPTED',
}

export interface WorkDTO {
  id?: string;
  unisonId?: string;
  title?: string;
  alternateTitles?: AlternateTitleAPI[];
  language?: string;
  iswc?: string;
  type?: string;
  workType?: string;
  versionType?: string;
  musicArrangement?: string;
  lyricAdaptation?: string;
  status?: string;
  // recordings: Recording[];
  // collectionShares: CollectionShare[];
  ownershipShares?: OwnershipShareDTO[];
  // performers: Performer;
  // tickets: Ticket[];
  // workUsages: WorkUsage[];
  updatedAt?: string;
  createdAt?: string;
  duration?: string;
  workUsages?: number; // TODO: Review
  recordingUsages?: number; // TODO: Review
  totalEarnings?: number; // TODO: Review
  royaltiesPaid?: number; // TODO: Review
  royaltiesPending?: number; // TODO: Review
  copyrightDate?: string;
  submitterWorkN?: string;
  copyrightNumber?: string;
  musicalWorkDistributionCategory?: string;
  recordedIndicator?: string;
  compositeType?: string;
  excerptType?: string;
  contactName?: string;
  contactId?: string;
  grandRightsIndicator?: boolean;
  compositeComponentCount?: number;
  datePublicationPrintedEdition?: string;
  exceptionalClause?: string;
  opusNumber?: string;
  catalogueNumber?: string;
  priorityFlag?: string;
  createdBy?: string;
  owners?: string;

  rightHolders?: RightHolderDTO[];

  usages?: number;
  source?: string;
  broadcastingStation?: string;

  date?: Date;
  consumer?: string;
  country?: string;

  earnings?: Array<number>;
  amount?: number;

  city?: string;
  event?: string;
  venue?: string;
  useType?: string;

  lyrics?: string;
}

export class Work implements Serializable<Work> {
  id?: string;
  unisonId?: string;
  title?: string;
  alternateTitles?: AlternateTitle[];
  language?: string;
  iswc?: string;
  type?: string;
  workType?: string;
  versionType?: string;
  musicArrangement?: string;
  lyricAdaptation?: string;
  status?: string;
  // recordings: Recording[];
  // collectionShares: CollectionShare[];
  ownershipShares?: OwnershipShare[];
  // performers: Performer;
  // tickets: Ticket[];
  // workUsages: workUsage[];
  updatedAt?: string;
  createdAt?: string;
  duration?: string;
  workUsages?: number; // TODO: Review
  recordingUsages?: number; // TODO: Review
  totalEarnings?: number; // TODO: Review
  royaltiesPaid?: number; // TODO: Review
  royaltiesPending?: number; // TODO: Review
  copyrightDate?: string;
  owners?: string;

  rightHolders?: RightHolder[];

  usages?: number;
  source?: string;
  broadcastingStation?: string;

  date?: Date;
  consumer?: string;
  country?: string;

  earnings?: Array<number>;
  amount?: number;

  city?: string;
  event?: string;
  venue?: string;
  useType?: string;

  lyrics?: string;

  deserialize(input: WorkDTO): Work {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.title = input.title || '';

    this.alternateTitles = input.alternateTitles
      ? new AlternateTitle().deserializeArray(input.alternateTitles)
      : [];

    this.iswc = input.iswc || '';
    this.language = input.language || '';
    this.status = input.status || '';
    this.type = input.type || '';
    this.workType = input.workType || '';
    this.versionType = input.versionType || '';
    this.duration = input.duration || '';
    this.musicArrangement = input.musicArrangement || '';
    this.lyricAdaptation = input.lyricAdaptation || '';
    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';
    this.copyrightDate = input.copyrightDate || '';
    this.owners = input.owners || '';

    this.ownershipShares = input.ownershipShares
      ? new OwnershipShare().deserializeArray(input.ownershipShares)
      : [];

    this.workUsages = input.workUsages || 0;
    this.recordingUsages = input.recordingUsages || 0;
    this.totalEarnings = input.totalEarnings || 0;
    this.royaltiesPaid = input.royaltiesPaid || 0;
    this.royaltiesPending = input.royaltiesPending || 0;

    this.rightHolders = input.rightHolders
      ? new RightHolder().deserializeArray(input.rightHolders)
      : [];

    this.usages = input.usages || 0;
    this.source = input.source || '';
    this.broadcastingStation = input.broadcastingStation || '';

    this.date = input.date || new Date();
    this.consumer = input.consumer || '';
    this.country = input.country || '';

    this.earnings = input.earnings || [];
    this.amount = input.amount || 0;

    this.city = input.city || '';
    this.event = input.event || '';
    this.venue = input.venue || '';
    this.useType = input.useType || '';

    this.lyrics = input.lyrics || '';

    return this;
  }

  deserializeArray(inputArray: Array<WorkDTO>): Array<Work> {
    const works: Array<Work> = inputArray.map((input) => new Work().deserialize(input));

    return works;
  }

  clone(): Work {
    const clonedInstance: Work = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
