import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { InfoResponseAPI } from 'app/shared/interfaces';
import { Rate, RateDTO } from 'app/shared/models';

export interface RatesResponse {
  rates: Rate[];
  info: InfoResponseAPI;
}
@Injectable({
  providedIn: 'root',
})
export class RateService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getRates(offset: number, limit: number, text: string = ''): Promise<RatesResponse> {
    const filters = `?offset=${offset}&limit=${limit}&title=${text}`;

    const url = `${this.apiUrl}/rate/${filters}`;

    const { rates: ratesApi, info } = await this.http
      .get<{ rates: Array<RateDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      rates: new Rate().deserializeArray(ratesApi),
      info,
    };
  }

  async getRate(rateId: string): Promise<Rate> {
    const url = `${this.apiUrl}/rate/${rateId}/`;

    const { rate: rateApi } = await this.http.get<{ rate: RateDTO }>(url).toPromise();

    return new Rate().deserialize(rateApi);
  }

  async createRate(rate: Rate): Promise<Rate> {
    const url = `${this.apiUrl}/rate/`;

    const { rate: rateApi } = await this.http.post<{ rate: RateDTO }>(url, rate).toPromise();

    return new Rate().deserialize(rateApi);
  }

  async updateRate(rateId: string, rate: Rate): Promise<Rate> {
    const url = `${this.apiUrl}/rate/${rateId}/`;

    const { rate: rateApi } = await this.http.put<{ rate: RateDTO }>(url, rate).toPromise();

    return new Rate().deserialize(rateApi);
  }

  async deleteRate(rateId: string): Promise<void> {
    const url = `${this.apiUrl}/rate/${rateId}/`;

    await this.http.delete<void>(url).toPromise();
  }
}
