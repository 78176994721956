import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import {
  Work,
  SourceTypes,
  WorkDTO,
  UsageDTO,
  Usage,
  UsageTypes,
  UsageStatus,
} from 'app/shared/models';
import {
  InfoResponseAPI,
  PlaysVsDownloadResponseDataI,
  UsageResponseAPII,
  WorkFiltersI,
  WorkTrendsAPI,
  WorkTrendsAPIResponse,
  WorkUsageI,
} from 'app/shared/interfaces';
import {
  DIGITAL_PLATFORMS_MOCK,
  PLAYS_VS_DOWNLOAD_DIGITAL_M,
  PLAYS_VS_DOWNLOAD_DIGITAL_QT,
  PLAYS_VS_DOWNLOAD_DIGITAL_Y,
  TOP_WORKS_USAGES_GENERAL,
  USAGES_DIGITAL_PLAYS_M,
  USAGES_DIGITAL_PLAYS_QT,
  USAGES_DIGITAL_PLAYS_Y,
  USAGES_LIVE_PLAYS_M,
  USAGES_LIVE_PLAYS_QT,
  USAGES_LIVE_PLAYS_Y,
  WORKS_USAGES_BACKGROUND_MOCK,
  WORKS_USAGES_DIGITAL_MOCK,
  WORKS_USAGES_DIGITAL_MOCK_M,
  WORKS_USAGES_DIGITAL_MOCK_QT,
  WORKS_USAGES_DIGITAL_MOCK_Y,
  WORKS_USAGES_LIVES_MOCK_M,
  WORKS_USAGES_LIVES_MOCK_QT,
  WORKS_USAGES_LIVES_MOCK_Y,
  WORKS_USAGES_LIVE_MOCK,
  WORKS_USAGES_RADIO_TV_MOCK,
  WORK_TRENDS_BACK_M,
  WORK_TRENDS_BACK_QT,
  WORK_TRENDS_BACK_Y,
  WORK_TRENDS_LIVE_PLAYS_M,
  WORK_TRENDS_LIVE_PLAYS_QT,
  WORK_TRENDS_LIVE_PLAYS_Y,
  WORK_TRENDS_PLAYS_M,
  WORK_TRENDS_PLAYS_QT,
  WORK_TRENDS_PLAYS_Y,
} from 'app/shared/mocks';
import { UsageShare, UsageShareDTO, UsageShareStatus } from '../models/usageShare';

// const SOURCETYPE = ['radio-tv', 'background', 'live'];
const TIMES = ['y', 'qt', 'm'];

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface UsageResponse {
  usages: Usage[];
  info: InfoResponseAPI;
}

export interface UsageShareResponse {
  usageShares: UsageShare[];
  info: InfoResponseAPI;
}

export interface TypePlays {
  type: string;
  plays: number;
}
export interface TrendPlayI {
  type: string;
  months: MonthlyPlays[];
}

export interface MonthlyPlays {
  month: string;
  plays: number;
}

export interface UsageFiltersI {
  text?: string;
  status?: string;
}

export interface UsagesFromConsumerFilterI {
  usageStatus?: UsageStatus;
  dateFrom?: string;
  dateTo?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UsageService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getUsageTypes(): Promise<string[]> {
    const url = `${this.apiUrl}/usage/types/`;
    const { types } = await this.http.get<{ types: string[] }>(url, httpOptions).toPromise();
    return types;
  }

  // TOTAL PLAYS
  async getTotalPlaysByTypeAndPeriod(period: string, types: string[]): Promise<TypePlays[]> {
    const url = `${this.apiUrl}/usage/plays`;
    const params = {
      period: period ? period.toString() : '',
      types: types.join(),
    };
    const { plays } = await this.http
      .get<{ plays: TypePlays[] }>(url, { params })
      .toPromise();

    return plays;
  }

  // TrendPlayI[]
  async getPlaysTrends(types: string[], period: string): Promise<TrendPlayI[]> {
    const url = `${this.apiUrl}/usage/plays/trend`;
    const params = {
      period: period ? period.toString() : '',
      types: types.join(),
    };

    const { trend: trendsPlaysApi } = await this.http
      .get<{ trend: TrendPlayI[] }>(url, { params })
      .toPromise();

    return trendsPlaysApi;
  }

  async getUsages(offset: number, limit: number, sourceType?: UsageTypes): Promise<UsageResponse> {
    const url = `${this.apiUrl}/usage?offset=${offset}&limit=${limit}&type=${sourceType}`;

    const { usages: usagesAPI, info } = await this.http
      .get<{ usages: UsageDTO[]; info: InfoResponseAPI }>(url, httpOptions)
      .toPromise();

    return {
      usages: new Usage().deserializeArray(usagesAPI),
      info,
    };
  }

  async getUsagesFromConsumer(
    consumerId: string,
    offset: number,
    limit: number,
    filters?: UsagesFromConsumerFilterI,
  ): Promise<UsageResponse> {
    const url = `${this.apiUrl}/consumer/${consumerId}/usages`;

    const params = {
      offset: offset ? offset.toString() : '0',
      limit: limit ? limit.toString() : '',
      status: filters?.usageStatus || '',
      dateFrom: filters?.dateFrom || '',
      dateTo: filters?.dateTo || '',
    };

    const { usages: usagesAPI, info } = await this.http
      .get<{ usages: UsageDTO[]; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      usages: new Usage().deserializeArray(usagesAPI),
      info,
    };
  }

  async getUsagesFromUser(
    userId: string,
    offset: number,
    limit: number,
    usageStatus?: UsageShareStatus | '',
  ): Promise<UsageShareResponse> {
    const url = `${this.apiUrl}/user/${userId}/usage-shares`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      status: usageStatus || '',
    };

    const { usageShares: usageSharesAPI, info } = await this.http
      .get<{ usageShares: UsageShareDTO[]; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      usageShares: new UsageShare().deserializeArray(usageSharesAPI),
      info,
    };
  }

  async getUsagesFromLiquidation(
    liquidationId: string,
    offset: number,
    limit: number,
    usageStatus?: UsageShareStatus | '',
  ): Promise<UsageShareResponse> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/usage-shares`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      status: usageStatus || '',
    };

    const { usageShares: usageSharesAPI, info } = await this.http
      .get<{ usageShares: UsageShareDTO[]; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      usageShares: new UsageShare().deserializeArray(usageSharesAPI),
      info,
    };
  }

  async getUsagesDetails(
    offset: number,
    limit: number,
    sourceType: string,
    text: string = '',
    status: string = '',
    consumer: string = '',
  ): Promise<{ info: InfoResponseAPI; usages: Usage[] | Work[] }> {
    let worksUsagesDetailsFetched: Array<Work>;
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      if (sourceType === SourceTypes.RADIO_TV) {
        // Apply filters to mockups
        const allRadioTvUsages = new Work()
          .deserializeArray(WORKS_USAGES_RADIO_TV_MOCK)
          .filter((workListened: Work) => {
            if (!text) {
              return true;
            }

            return workListened.title.includes(text) || workListened.unisonId.includes(text);
          });

        // Apply pagination on filtered
        worksUsagesDetailsFetched = allRadioTvUsages.slice(offset, offset + limit);

        infoResponse = {
          count: allRadioTvUsages.length,
          offset: offset.toString(),
          limit: limit.toString(),
        };
      } else if (sourceType === SourceTypes.BACKGROUND) {
        // Apply filters to mockups
        const allBackgroundUsages = new Work()
          .deserializeArray(WORKS_USAGES_BACKGROUND_MOCK)
          .filter((workListened: Work) => {
            if (!text) {
              return true;
            }

            return workListened.title.includes(text) || workListened.unisonId.includes(text);
          });

        // Apply pagination on filtered
        worksUsagesDetailsFetched = allBackgroundUsages.slice(offset, offset + limit);

        infoResponse = {
          count: allBackgroundUsages.length,
          offset: offset.toString(),
          limit: limit.toString(),
        };
      } else if (sourceType === SourceTypes.LIVE) {
        // Apply filters to mockups
        const allLiveUsages = new Work()
          .deserializeArray(WORKS_USAGES_LIVE_MOCK)
          .filter((workListened: Work) => {
            if (!text) {
              return true;
            }

            return workListened.title.includes(text) || workListened.unisonId.includes(text);
          });

        // Apply pagination on filtered
        worksUsagesDetailsFetched = allLiveUsages.slice(offset, offset + limit);

        infoResponse = {
          count: allLiveUsages.length,
          offset: offset.toString(),
          limit: limit.toString(),
        };
      } else if (sourceType === SourceTypes.DIGITAL) {
        // Apply filters to mockups
        const allDigitalUsages = new Work()
          .deserializeArray(WORKS_USAGES_DIGITAL_MOCK)
          .filter((workListened: Work) => {
            if (!text && !consumer) {
              return true;
            }

            if (consumer) {
              return workListened.consumer.includes(consumer);
            }
            return workListened.title.includes(text) || workListened.unisonId.includes(text);
          });

        // Apply pagination on filtered
        worksUsagesDetailsFetched = allDigitalUsages.slice(offset, offset + limit);

        infoResponse = {
          count: allDigitalUsages.length,
          offset: offset.toString(),
          limit: limit.toString(),
        };
      }
    } else {
      const params = {
        offset: offset ? offset.toString() : '',
        limit: limit ? limit.toString() : '',
        type: sourceType ? sourceType.toString() : '',
        text: text ? text.toString() : '',
        status: status ? status.toString() : '',
      };

      const url = `${this.apiUrl}/usage`;

      const { info, usages: usagesApi } = await this.http
        .get<{ info: InfoResponseAPI; usages: UsageDTO[] }>(url, { params })
        .toPromise();
      return {
        usages: new Usage().deserializeArray(usagesApi),
        // filters,
        info,
      };
    }
    return {
      usages: worksUsagesDetailsFetched,
      // filters,
      info: infoResponse,
    };
  }

  async getBackgroundTrendsBySource(timeFilter: string = null): Promise<WorkTrendsAPI[]> {
    let filter = '';
    let workTrends: WorkTrendsAPI[] = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        workTrends = WORK_TRENDS_BACK_Y;
      } else if (timeFilter === TIMES[1]) {
        workTrends = WORK_TRENDS_BACK_QT;
      } else if (timeFilter === TIMES[2]) {
        workTrends = WORK_TRENDS_BACK_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/composition/work-trends-source/${filter}`; // TODO: Endpoint
      const response: WorkTrendsAPIResponse = await this.http
        .get<WorkTrendsAPIResponse>(url, httpOptions)
        .toPromise();
      workTrends = response.trends;
    }
    return workTrends;
  }

  async getBackgroundTrendPlays(timeFilter: string = null): Promise<WorkTrendsAPI[]> {
    let filter = '';
    let workTrends: WorkTrendsAPI[] = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        workTrends = WORK_TRENDS_PLAYS_Y;
      } else if (timeFilter === TIMES[1]) {
        workTrends = WORK_TRENDS_PLAYS_QT;
      } else if (timeFilter === TIMES[2]) {
        workTrends = WORK_TRENDS_PLAYS_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/composition/work-trends-source/${filter}`; // TODO: Endpoint
      const response: WorkTrendsAPIResponse = await this.http
        .get<WorkTrendsAPIResponse>(url, httpOptions)
        .toPromise();
      workTrends = response.trends;
    }
    return workTrends;
  }

  async getLiveTrends(timeFilter: string = null): Promise<WorkTrendsAPI[]> {
    let filter = '';
    let workTrends: WorkTrendsAPI[] = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        workTrends = WORK_TRENDS_LIVE_PLAYS_Y;
      } else if (timeFilter === TIMES[1]) {
        workTrends = WORK_TRENDS_LIVE_PLAYS_QT;
      } else if (timeFilter === TIMES[2]) {
        workTrends = WORK_TRENDS_LIVE_PLAYS_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/composition/work-trends-source/${filter}`; // TODO: Endpoint
      const response: WorkTrendsAPIResponse = await this.http
        .get<WorkTrendsAPIResponse>(url, httpOptions)
        .toPromise();
      workTrends = response.trends;
    }
    return workTrends;
  }

  async getUsagesDetailsWithTimeframe(
    offset: number,
    limit: number,
    sourceType: SourceTypes,
    filters: WorkFiltersI,
    timeFilter: string = null,
  ): Promise<UsageResponseAPII> {
    let worksUsagesDetailsFetched: Array<Work>;
    let infoResponse: InfoResponseAPI;
    let allLiveUsages: Array<Work>;
    let allDigitalWorkUsages: Array<Work> = [];
    if (environment.useMocks) {
      if (sourceType === SourceTypes.LIVE) {
        if (timeFilter === TIMES[0]) {
          allLiveUsages = new Work().deserializeArray(WORKS_USAGES_LIVES_MOCK_Y);
          // workTrends = WORK_TRENDS_LIVE_PLAYS_Y;
        } else if (timeFilter === TIMES[1]) {
          allLiveUsages = new Work().deserializeArray(WORKS_USAGES_LIVES_MOCK_M);
          // workTrends = WORK_TRENDS_LIVE_PLAYS_QT;
        } else if (timeFilter === TIMES[2]) {
          allLiveUsages = new Work().deserializeArray(WORKS_USAGES_LIVES_MOCK_QT);
          // workTrends = WORK_TRENDS_LIVE_PLAYS_M;
        } else {
          // Apply filters to mockups
          allLiveUsages = new Work()
            .deserializeArray(WORKS_USAGES_LIVE_MOCK)
            .filter((workListened: Work) => {
              if (!filters.text) {
                return true;
              }

              return (
                workListened.title.includes(filters.text) ||
                workListened.unisonId.includes(filters.text)
              );
            });
        }

        // Apply pagination on filtered
        worksUsagesDetailsFetched = allLiveUsages.slice(offset, offset + limit);

        infoResponse = {
          count: allLiveUsages.length,
          offset: offset.toString(),
          limit: limit.toString(),
        };
      } else if (sourceType === SourceTypes.DIGITAL) {
        if (timeFilter === TIMES[0]) {
          allDigitalWorkUsages = new Work()
            .deserializeArray(WORKS_USAGES_DIGITAL_MOCK_Y)
            .filter((workListened: Work) => {
              if (!filters.consumer) {
                return true;
              }

              return workListened.consumer.includes(filters.consumer);
            });
        } else if (timeFilter === TIMES[1]) {
          allDigitalWorkUsages = new Work()
            .deserializeArray(WORKS_USAGES_DIGITAL_MOCK_QT)
            .filter((workListened: Work) => {
              if (!filters.consumer) {
                return true;
              }

              return workListened.consumer.includes(filters.consumer);
            });
        } else if (timeFilter === TIMES[2]) {
          allDigitalWorkUsages = new Work()
            .deserializeArray(WORKS_USAGES_DIGITAL_MOCK_M)
            .filter((workListened: Work) => {
              if (!filters.consumer) {
                return true;
              }

              return workListened.consumer.includes(filters.consumer);
            });
        } else {
          // Apply filters to mockups
          allDigitalWorkUsages = new Work()
            .deserializeArray(WORKS_USAGES_DIGITAL_MOCK)
            .filter((workListened: Work) => {
              if (!filters.text) {
                return true;
              }

              return (
                workListened.title.includes(filters.text) ||
                workListened.unisonId.includes(filters.text)
              );
            });
        }

        // Apply pagination on filtered
        worksUsagesDetailsFetched = allDigitalWorkUsages.slice(offset, offset + limit);

        infoResponse = {
          count: allDigitalWorkUsages.length,
          offset: offset.toString(),
          limit: limit.toString(),
        };
      }
    } else {
      // TODO: Implement API Call
      const url = `${this.apiUrl}/ticket/me/${sourceType}`;
      await this.http.get<UsageResponseAPII>(url, httpOptions).toPromise();
    }

    return {
      usages: worksUsagesDetailsFetched,
      filters,
      info: infoResponse,
    };
  }

  async getLiveUsagesBySource(timeFilter: string = null): Promise<WorkUsageI[]> {
    let filter = '';
    let liveUsages: WorkUsageI[] = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        liveUsages = USAGES_LIVE_PLAYS_Y;
      } else if (timeFilter === TIMES[1]) {
        liveUsages = USAGES_LIVE_PLAYS_QT;
      } else if (timeFilter === TIMES[2]) {
        liveUsages = USAGES_LIVE_PLAYS_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/composition/work-trends-source/${filter}`; // TODO: Endpoint
      const response: WorkUsageI[] = await this.http
        .get<WorkUsageI[]>(url, httpOptions)
        .toPromise();
      liveUsages = response;
    }
    return liveUsages;
  }

  async getTotalUsages(
    offset: number,
    limit: number,
    filters?: UsageFiltersI,
  ): Promise<{ info: InfoResponseAPI; usages: Usage[] }> {
    let params = `?offset=${offset}&limit=${limit}`;

    if (filters?.text) {
      params += `&text=${filters?.text}`;
    }
    const url = `${this.apiUrl}/usage/${params}`;
    const { info, usages: usagesApi } = await this.http
      .get<{ info: InfoResponseAPI; usages: UsageDTO[] }>(url, httpOptions)
      .toPromise();

    return {
      usages: new Usage().deserializeArray(usagesApi),
      info,
    };
  }

  async getPlaysByDigitalSource(timeFilter: string = null): Promise<WorkUsageI[]> {
    let filter = '';
    let liveUsages: WorkUsageI[] = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        liveUsages = USAGES_DIGITAL_PLAYS_Y;
      } else if (timeFilter === TIMES[1]) {
        liveUsages = USAGES_DIGITAL_PLAYS_QT;
      } else if (timeFilter === TIMES[2]) {
        liveUsages = USAGES_DIGITAL_PLAYS_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/composition/work-trends-source/${filter}`; // TODO: Endpoint
      const response: WorkUsageI[] = await this.http
        .get<WorkUsageI[]>(url, httpOptions)
        .toPromise();
      liveUsages = response;
    }
    return liveUsages;
  }

  async getPlaysVsDownloadsDigitalData(
    timeFilter: string = null,
  ): Promise<PlaysVsDownloadResponseDataI> {
    let filter = '';
    let playsVsDownload: PlaysVsDownloadResponseDataI;
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        playsVsDownload = PLAYS_VS_DOWNLOAD_DIGITAL_Y;
      } else if (timeFilter === TIMES[1]) {
        playsVsDownload = PLAYS_VS_DOWNLOAD_DIGITAL_QT;
      } else if (timeFilter === TIMES[2]) {
        playsVsDownload = PLAYS_VS_DOWNLOAD_DIGITAL_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/composition/work-trends-source/${filter}`; // TODO: Endpoint
      const response: PlaysVsDownloadResponseDataI = await this.http
        .get<PlaysVsDownloadResponseDataI>(url, httpOptions)
        .toPromise();
      playsVsDownload = response;
    }
    return playsVsDownload;
  }

  async getDigitalPlatforms(): Promise<Array<string>> {
    let digitalPlatforms: Array<string> = [];
    if (environment.useMocks) {
      digitalPlatforms = DIGITAL_PLATFORMS_MOCK;
    } else {
      try {
        const url = `${this.apiUrl}///`; // TODO: Endpoint
        digitalPlatforms = await this.http.get<Array<string>>(url, httpOptions).toPromise();
        digitalPlatforms = DIGITAL_PLATFORMS_MOCK;
      } catch (error) {
        // console.log("UsageService -> constructor -> error", error)
      }
    }
    return digitalPlatforms;
  }

  async deleteUsage(usage: Usage): Promise<void> {
    const url = `${this.apiUrl}/usage/${usage.id}?type=${usage.type}`;
    await this.http.delete(url).toPromise();
  }

  async editUsage(usage: Usage): Promise<void> {
    const url = `${this.apiUrl}/usage/${usage.id}`;
    const response = await this.http.put(url, usage).toPromise();
    console.log('🚀 ~ editUsage ~ response', response);
  }

  addNewUsage(usage: Usage): Promise<void> {
    if (usage.recordingId) {
      return this.addNewMusicRecordingUsage(usage);
    }

    return this.addNewMusicWorkUsage(usage);
  }

  private async addNewMusicRecordingUsage(usage: Usage): Promise<void> {
    const url = `${this.apiUrl}/usage`;

    await this.http.post(url, usage).toPromise();
  }

  private async addNewMusicWorkUsage(usage: Usage): Promise<void> {
    const url = `${this.apiUrl}/usage`;

    await this.http.post(url, usage).toPromise();
  }
}
