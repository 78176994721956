import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';

import { BehaviorSubject } from 'rxjs';

import { FinancialData, User, UserDTO } from 'app/shared/models';
import {
  FinancialDataAPIResponse,
  UserResponseAPII,
  UsersResponseAPII,
  UsersResponseI,
} from 'app/shared/interfaces';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

interface NewPasswordI {
  newPassword: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl: string = environment.apiUrl;

  private readonly userSubject = new BehaviorSubject<User>(null);
  readonly currentUser$ = this.userSubject.asObservable();

  get currentUser(): User {
    return this.userSubject.getValue();
  }

  set currentUser(user: User) {
    this.userSubject.next(user);
  }

  constructor(private http: HttpClient) {}

  async getUsers(offset: number, limit: number, searchText: string): Promise<UsersResponseI> {
    let usersFetched: User[] = [];
    let filters = `?offset=${offset}&limit=${limit}`;
    if (searchText) {
      filters += `&text=${searchText}`;
    }
    const url = `${this.apiUrl}/user/${filters}`;
    const response: UsersResponseAPII = await this.http.get<UsersResponseAPII>(url).toPromise();
    usersFetched = new User().deserializeArray(response.users);
    return { users: usersFetched, info: response.info };
  }

  async getUser(id: string): Promise<User> {
    let userFetched: User = new User();
    const url = `${this.apiUrl}/user/${id}`;
    const response: UserResponseAPII = await this.http.get<UserResponseAPII>(url).toPromise();
    userFetched = new User().deserialize(response.user);
    return userFetched;
  }

  async changePassword(id: string, newPassword: string): Promise<User> {
    const data = { newPassword };
    let userUpdated: User = new User();
    const url = `${this.apiUrl}/user/${id}/change-password`;
    const response: UserResponseAPII = await this.http.put<UserResponseAPII>(url, data).toPromise();
    userUpdated = new User().deserialize(response.user);
    return userUpdated;
  }

  async getUserFinancialData(id: string): Promise<FinancialData> {
    let financialDataFetched: FinancialData;

    const url = `${this.apiUrl}/user/${id}/financial-data`;
    const response: FinancialDataAPIResponse = await this.http
      .get<FinancialDataAPIResponse>(url)
      .toPromise();
    if (response.financialData.type) {
      financialDataFetched = new FinancialData().deserialize(response.financialData);
    } else {
      financialDataFetched = new FinancialData();
    }

    return financialDataFetched;
  }

  async updateUser(user: User): Promise<User> {
    let userUpdated: User = user;

    if (environment.useMocks) {
      // const session = this.authService.getCurrentSession();
      // session.user.email = user.email;
      // session.user.firstName = user.firstName;
      // session.user.email = user.email;
    } else {
      const url = `${this.apiUrl}/user/${user.id}/`;
      const response: UserResponseAPII = await this.http
        .put<UserResponseAPII>(url, user, httpOptions)
        .toPromise();

      userUpdated = new User().deserialize(response.user);
      this.currentUser = userUpdated;
    }

    return userUpdated;
  }

  async updateProfile(user: User): Promise<User> {
    let userUpdated: User = user;

    if (environment.useMocks) {
      // const session = this.authService.getCurrentSession();
      // session.user.email = user.email;
      // session.user.firstName = user.firstName;
      // session.user.email = user.email;
    } else {
      const url = `${this.apiUrl}/user/me/`;
      const response: UserDTO = await this.http.put<UserDTO>(url, user, httpOptions).toPromise();
      userUpdated = new User().deserialize(response);
    }

    return userUpdated;
  }

  async changeCurrentUserPassword(data: NewPasswordI): Promise<boolean> {
    let passwordChanged = false;
    if (environment.useMocks) {
      passwordChanged = true;
    } else {
      const url = `${this.apiUrl}/user/me/change-password`;
      try {
        await this.http.put(url, data, httpOptions).toPromise();
        passwordChanged = true;
      } catch (error) {
        // console.log(error);
      }
    }
    return passwordChanged;
  }

  async getFinancialData(): Promise<FinancialData> {
    let financialDataFetched: FinancialData;

    if (environment.useMocks) {
      // financialDataFetched = new FinancialData().deserialize(FINANCIALDATA_BUSINESS);
      // financialDataFetched = new FinancialData().deserialize(FINANCIALDATA_INDIVIDUAL);
      financialDataFetched = new FinancialData();
    } else {
      const url = `${this.apiUrl}/user/me/financial-data`;
      const response: FinancialDataAPIResponse = await this.http
        .get<FinancialDataAPIResponse>(url)
        .toPromise();
      if (response.financialData.type) {
        financialDataFetched = new FinancialData().deserialize(response.financialData);
      } else {
        financialDataFetched = new FinancialData();
      }
    }
    return financialDataFetched;
  }

  async updateFinancialData(financialData: FinancialData): Promise<void> {
    if (environment.useMocks) {
      // console.log("UPDATED FINANCIAL DATA");
    } else {
      const data = {
        financialData,
      };
      const url = `${this.apiUrl}/user/me/financial-data`;
      await this.http.put(url, data).toPromise();
    }
  }

  async updateUserFinancialData(id: string, financialData: FinancialData): Promise<void> {
    const url = `${this.apiUrl}/user/${id}/financial-data`;
    const data = {
      financialData,
    };

    await this.http.put(url, data).toPromise();
  }

  async createUser(user: User): Promise<User> {
    const url = `${this.apiUrl}/user/`;
    const response = await this.http.post<{ user: UserDTO }>(url, user, httpOptions).toPromise();

    return new User().deserialize(response.user);
  }

  async deleteUser(userId: string): Promise<void> {
    const url = `${this.apiUrl}/user/${userId}/`;
    await this.http.delete(url).toPromise();
  }
}
