import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { Consumer, ConsumerDTO, Platform, PlatformDTO } from 'app/shared/models';
import { BehaviorSubject } from 'rxjs';

export interface ConsumersResponse {
  consumers: Consumer[];
  info: InfoResponseAPI;
}

export interface PlatformResponse {
  platforms: Array<Platform>;
  info: InfoResponseAPI;
}

export interface ConsumerFilters {
  text?: string;
  platformId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConsumerService {
  readonly apiUrl: string = environment.apiUrl;

  private readonly consumerSubject = new BehaviorSubject<Consumer>(null);
  readonly currentConsumer$ = this.consumerSubject.asObservable();

  get currentConsumer(): Consumer {
    return this.consumerSubject.getValue();
  }

  set currentConsumer(consumer: Consumer) {
    this.consumerSubject.next(consumer);
  }

  constructor(private http: HttpClient) {}

  async getConsumers(
    offset: number,
    limit: number,
    filters?: ConsumerFilters,
  ): Promise<ConsumersResponse> {
    const url = `${this.apiUrl}/consumer`;
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      text: filters?.text ? filters.text : '',
      platformId: filters?.platformId ? filters.platformId : '',
    };

    const { consumers: consumersApi, info } = await this.http
      .get<{ consumers: Array<ConsumerDTO>; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      consumers: new Consumer().deserializeArray(consumersApi),
      info,
    };
  }

  async getConsumer(consumerId: string): Promise<Consumer> {
    const url = `${this.apiUrl}/consumer/${consumerId}/`;

    const { consumer: consumerApi } = await this.http
      .get<{ consumer: ConsumerDTO }>(url)
      .toPromise();

    return new Consumer().deserialize(consumerApi);
  }

  async createConsumer(consumer: Consumer): Promise<Consumer> {
    const url = `${this.apiUrl}/consumer/`;

    const { consumer: consumerApi } = await this.http
      .post<{ consumer: ConsumerDTO }>(url, consumer)
      .toPromise();

    return new Consumer().deserialize(consumerApi);
  }

  async updateConsumer(consumerId: string, consumer: Consumer): Promise<Consumer> {
    const url = `${this.apiUrl}/consumer/${consumerId}/`;

    const { consumer: consumerApi } = await this.http
      .put<{ consumer: ConsumerDTO }>(url, consumer)
      .toPromise();

    return new Consumer().deserialize(consumerApi);
  }

  async deleteConsumer(consumerId: string): Promise<void> {
    const url = `${this.apiUrl}/consumer/${consumerId}/`;

    await this.http.delete<void>(url).toPromise();
  }

  // #### PLATFORMS ############################################################

  async getPlatforms(offset: number, limit: number, text: string = ''): Promise<PlatformResponse> {
    const filters = `?offset=${offset}&limit=${limit}&text=${text}`;

    const url = `${this.apiUrl}/platform/${filters}`;

    const { platforms: platformsApi, info } = await this.http
      .get<{ platforms: Array<ConsumerDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      platforms: new Platform().deserializeArray(platformsApi),
      info,
    };
  }

  async createPlatform(name: string): Promise<Platform> {
    const url = `${this.apiUrl}/platform/`;

    const { platform: platformApi } = await this.http
      .post<{ platform: PlatformDTO }>(url, { name })
      .toPromise();

    return new Platform().deserialize(platformApi);
  }

  async deletePlatform(platformId: string): Promise<void> {
    const url = `${this.apiUrl}/platform/${platformId}/`;

    await this.http.delete<void>(url).toPromise();
  }
}
