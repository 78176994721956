/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Territory, TerritoryDTO } from './territory';
import { WorkDTO, Work } from './work';
import { Consumer, ConsumerDTO } from './consumer';
import { Claim, ClaimDTO } from './claim';
import { UsageTypes } from './usage';
import { UsageShare, UsageShareDTO } from './usageShare';

export class WorkUsageDTO {
  id: string;
  datetime: string;
  amount: number;
  artistName: string;
  status: string;
  territory: TerritoryDTO;
  territoryId: number;
  work: WorkDTO;
  workId: string;
  composition: WorkDTO;
  compositionId: string;
  consumer: ConsumerDTO;
  consumerId: string;
  invoice: ClaimDTO;
  type: UsageTypes;
  invoiceId: string;
  claim: ClaimDTO;
  claimId: string;
  updatedAt: string;
  createdAt: string;
  dateFrom: string;
  dateTo: string;
  usageShares: Array<UsageShareDTO>;
}

export class WorkUsage implements Serializable<WorkUsage> {
  id: string;
  datetime: string;
  amount: number;
  artistName: string;
  status: string;
  type: UsageTypes;
  territory: Territory;
  territoryId: number;
  work: Work;
  workId: string;
  consumer: Consumer;
  consumerId: string;
  claim: Claim;
  claimId: string;
  updatedAt: string;
  createdAt: string;
  dateFrom: string;
  dateTo: string;
  usageShares: Array<UsageShare>;

  deserialize(input: WorkUsageDTO): WorkUsage {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.datetime = input.datetime || '';
    this.amount = input.amount || 0;
    this.artistName = input.artistName || '';
    this.status = input.status || '';
    this.territoryId = input.territoryId || 0;
    this.consumerId = input.consumerId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.dateFrom = input.dateFrom || '';
    this.dateTo = input.dateTo || '';
    this.type = input.type || UsageTypes.DIGITAL;
    this.territory = new Territory().deserialize(input.territory);
    this.consumer = input.consumer ? new Consumer().deserialize(input.consumer) : null;

    this.usageShares = input.usageShares
      ? new UsageShare().deserializeArray(input.usageShares)
      : [];

    if (input.composition) {
      this.work = new Work().deserialize(input.composition);
      this.workId = input.compositionId;
    }

    if (input.work) {
      this.work = new Work().deserialize(input.work);
      this.workId = input.workId;
    }

    if (input.invoice) {
      this.claim = new Claim().deserialize(input.invoice);
      this.claimId = input.invoiceId || '';
    }

    if (input.claim) {
      this.claim = new Claim().deserialize(input.claim);
      this.claimId = input.claimId || '';
    }

    return this;
  }

  deserializeArray(inputArray: Array<WorkUsageDTO>): Array<WorkUsage> {
    const workUsages: WorkUsage[] = inputArray.map((input) => new WorkUsage().deserialize(input));

    return workUsages;
  }

  clone(): WorkUsage {
    const clonedInstance: WorkUsage = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
