import { Observable, Subject, throwError } from 'rxjs';
import { catchError, throttleTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { AuthService } from 'app/modules/auth/services';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private throttleLogout = new Subject();

  constructor(private authService: AuthService, private router: Router) {
    this.throttleLogout.pipe(throttleTime(5000)).subscribe(() => {
      this.authService.logout();

      return this.router.navigate(['/auth/login']);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (this.authService.isAuthenticated()) {
      const token = this.authService.getCurrentToken();

      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          this.throttleLogout.next();
        }

        return throwError(response);
      }),
    );
  }
}
