import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import {
  ROYALTIES_PLATFORM_Y,
  ROYALTIES_PLATFORM_QT,
  ROYALTIES_PLATFORM_M,
  ROYALTIES_SOURCE_Y,
  ROYALTIES_SOURCE_QT,
  ROYALTIES_SOURCE_M,
  ROYALTIES_LAST_QUARTER_SUMMARY,
  EARNINGS_BY_WORK,
  EARNINGS_BY_WORK_TOP,
} from 'app/shared/mocks';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  EarningsByWorkResponseAPII,
  InfoResponseAPI,
  RoyaltiesByPlatformAPIResponse,
  RoyaltiesPlatformI,
  RoyaltiesSummaryI,
  WorkFiltersI,
  TimeframeOptionI,
} from 'app/shared/interfaces';
import { PeriodOptions } from 'app/shared/constants';
import { Platform, UsageTypes, Work, Consumer } from 'app/shared/models';

const TIMES = ['y', 'qt', 'm'];
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface PlatformRoyaltiesTrend {
  trend: Array<{
    platform: Platform;
    total: number;
    months: Array<{ month: string; royalties: number }>;
  }>;
}

interface ConsumerRoyaltiesTrendFilters {
  period: TimeframeOptionI;
  territoryId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class EarningsService {
  private readonly apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  async getRoyaltiesByDigitalPlatform(timeFilter: string = null): Promise<RoyaltiesPlatformI[]> {
    let filter = '';
    let royaltiesByDigitalPlatform: Array<RoyaltiesPlatformI> = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        royaltiesByDigitalPlatform = ROYALTIES_PLATFORM_Y;
      } else if (timeFilter === TIMES[1]) {
        royaltiesByDigitalPlatform = ROYALTIES_PLATFORM_QT;
      } else if (timeFilter === TIMES[2]) {
        royaltiesByDigitalPlatform = ROYALTIES_PLATFORM_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/earnings-platform/${filter}`; // TODO: Endpoint
      const response: RoyaltiesByPlatformAPIResponse = await this.http
        .get<RoyaltiesByPlatformAPIResponse>(url, httpOptions)
        .toPromise();
      royaltiesByDigitalPlatform = response.royalties;
    }
    return royaltiesByDigitalPlatform;
  }

  async getRoyaltiesBySource(timeFilter: string = null): Promise<RoyaltiesPlatformI[]> {
    let filter = '';
    let royaltiesBySource: Array<RoyaltiesPlatformI> = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        royaltiesBySource = ROYALTIES_SOURCE_Y;
      } else if (timeFilter === TIMES[1]) {
        royaltiesBySource = ROYALTIES_SOURCE_QT;
      } else if (timeFilter === TIMES[2]) {
        royaltiesBySource = ROYALTIES_SOURCE_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/earnings-source/${filter}`; // TODO: Endpoint
      const response: RoyaltiesByPlatformAPIResponse = await this.http
        .get<RoyaltiesByPlatformAPIResponse>(url, httpOptions)
        .toPromise();
      royaltiesBySource = response.royalties;
    }
    return royaltiesBySource;
  }

  async getRoyaltiesLastQuarterSummary(): Promise<RoyaltiesSummaryI> {
    let royaltiesLastQuarterSummary: RoyaltiesSummaryI = null;
    if (environment.useMocks) {
      royaltiesLastQuarterSummary = ROYALTIES_LAST_QUARTER_SUMMARY;
    } else {
      const url = `${this.apiUrl}/earnings-summary/`; // TODO: Endpoint
      const response: RoyaltiesSummaryI = await this.http
        .get<RoyaltiesSummaryI>(url, httpOptions)
        .toPromise();
      royaltiesLastQuarterSummary = response;
    }
    return royaltiesLastQuarterSummary;
  }

  getWorksEarningsByYear(
    offset: number,
    limit: number,
    filters: WorkFiltersI,
  ): EarningsByWorkResponseAPII {
    let earningsByWorkFetched: Work[];
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      const allEarningsByWork = new Work()
        .deserializeArray(EARNINGS_BY_WORK.earningsByWork)
        .filter((earningWork: Work) => {
          if (!filters.text) {
            return true;
          }

          return (
            earningWork.title.includes(filters.text) || earningWork.unisonId.includes(filters.text)
          );
        });

      earningsByWorkFetched = allEarningsByWork.slice(offset, offset + limit);

      infoResponse = {
        count: allEarningsByWork.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };
    }

    return {
      earningsByWork: earningsByWorkFetched,
      yearEarnings: EARNINGS_BY_WORK.yearEarnings,
      info: infoResponse,
    };
  }

  getWorksEarningsByYearTop(numWorks: number, numYears: number): EarningsByWorkResponseAPII {
    let earningsByWorkFetched: Work[];
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      earningsByWorkFetched = new Work().deserializeArray(EARNINGS_BY_WORK_TOP.earningsByWork);

      infoResponse = {
        count: earningsByWorkFetched.length,
        offset: '0',
        limit: '0',
      };
    } else {
      // TODO: API Call
      // eslint-disable-next-line no-console
      console.log(numWorks, numYears);
    }

    return {
      earningsByWork: earningsByWorkFetched,
      yearEarnings: EARNINGS_BY_WORK.yearEarnings,
      info: infoResponse,
    };
  }

  async getConsumerRoyaltiesTrend(
    consumer: Consumer,
    filters: ConsumerRoyaltiesTrendFilters,
  ): Promise<PlatformRoyaltiesTrend> {
    // const url = `${this.apiUrl}/usage/royalties/trend`;
    const url = `${this.apiUrl}/liquidation/earnings/trend`;
    const params = {
      period: filters?.period ? filters.period.value : PeriodOptions.LAST_YEAR.value,
      territoryId: filters?.territoryId ? filters.territoryId.toString() : '',
      consumerId: consumer.id,
      types: consumer.type,
    };
    const response = await this.http
      .get<PlatformRoyaltiesTrend>(url, { params })
      .toPromise();

    return response;
    // return this.getPlatformRoyaltiesTrend('', filters.period);
  }

  async getPlatformRoyaltiesTrend(
    type: UsageTypes | '' = '',
    period: TimeframeOptionI = PeriodOptions.LAST_YEAR,
  ): Promise<PlatformRoyaltiesTrend> {
    const url = `${this.apiUrl}/platform/royalties/trend`;
    const params = {
      period: period ? period.value : '',
      type,
    };
    const response = await this.http
      .get<PlatformRoyaltiesTrend>(url, { params })
      .toPromise();

    return response;
  }
}
