export const environment = {
  production: false,
  demo: false,
  staging: true,
  development: false,
  maintenance: false,
  useMocks: false,
  sessionKey: 'UNISON_SESSION_BACKOFFICE',
  // apiUrl: 'http://localhost:3000/api',
  apiUrl: 'https://api.staging.unisonrights.online/api',
  resetApiUrl: 'https://api.staging.unisonrights.online',
};
