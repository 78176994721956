import { Serializable } from 'app/core/interfaces';

export interface CurrencyDTO {
  id: string;
  name: string;
}

export class Currency implements Serializable<Currency> {
  id: string;
  name: string;

  deserialize(input: CurrencyDTO): Currency {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';

    return this;
  }

  deserializeArray(inputArray: Array<CurrencyDTO>): Array<Currency> {
    const currencies: Array<Currency> = inputArray.map((input) =>
      new Currency().deserialize(input),
    );

    return currencies;
  }

  clone(): Currency {
    const clonedInstance: Currency = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
