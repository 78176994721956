import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFilesizeModule } from 'ngx-filesize';

import { NgSelectModule } from '@ng-select/ng-select';

import { ToastrModule } from 'ngx-toastr';

import { LoggedInGuard } from 'app/modules/auth/guards';
import { MaintenanceGuard } from 'app/modules/maintenance/guards';
import { AuthService } from 'app/modules/auth/services';

import { TruncatePipe, RemoveUnderscoreAndCapitalizePipe } from 'app/shared/pipes';

import {
  EarningsService,
  WorkService,
  RecordingService,
  ClaimService,
  TicketService,
  UserService,
  UsageService,
  TokenInterceptorService,
  CatalogService,
  CurrencyService,
  CountryService,
  PartnerService,
  RateService,
  ConsumerService,
  LiquidationService,
  UsageReportService,
  WorksRequestQueueService,
  CsvParserService,
  CwrParserService,
  SocietyService,
  StatementService,
  DocumentService,
  PlatformService,
} from './services';

import {
  AppHeaderComponent,
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderMenuComponent,
  APP_SIDEBAR_NAV,
  AppHeaderUnitedComponent,
  ModalProgressComponent,
} from './components';
import { SidebarToggleDirective, NAV_DROPDOWN_DIRECTIVES } from './directives';
import { P404PageComponent } from './pages';

const PAGES: any[] = [P404PageComponent];

const COMPONENTS: any[] = [
  AppHeaderComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderMenuComponent,
  ...APP_SIDEBAR_NAV,
  AppHeaderUnitedComponent,
  ModalProgressComponent,
];

const PROVIDERS: Provider[] = [
  AuthService,
  LoggedInGuard,
  MaintenanceGuard,
  UserService,
  WorkService,
  RecordingService,
  ClaimService,
  TicketService,
  EarningsService,
  UsageService,
  CatalogService,
  CurrencyService,
  CountryService,
  PartnerService,
  RecordingService,
  RateService,
  ConsumerService,
  LiquidationService,
  UsageReportService,
  WorksRequestQueueService,
  CsvParserService,
  CwrParserService,
  SocietyService,
  StatementService,
  DocumentService,
  PlatformService,
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
];

const MODULES: any[] = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  NgxSpinnerModule,
  ChartsModule,
  NgxPaginationModule,
  NgSelectModule,
  NgbModule,
  NgxFilesizeModule,
];

const DIRECTIVES: Provider[] = [SidebarToggleDirective, ...NAV_DROPDOWN_DIRECTIVES];

const PIPES: any[] = [TruncatePipe, RemoveUnderscoreAndCapitalizePipe];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    ...(MODULES as []),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
  ],
  declarations: [...(PAGES as []), ...(COMPONENTS as []), ...(DIRECTIVES as []), ...(PIPES as [])],
  exports: [
    ...(PAGES as []),
    ...(COMPONENTS as []),
    ...(DIRECTIVES as []),
    ...(MODULES as []),
    ...(PIPES as []),
    TranslateModule,
    ToastrModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS, ...DIRECTIVES],
    } as ModuleWithProviders<SharedModule>;
  }

  constructor(private translate: TranslateService) {
    this.initTranslateService();
  }

  private initTranslateService(): void {
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');

    // user browser language
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/es|en/) ? browserLang : 'es');
  }
}
