import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocietyService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async createSocietyBatch(societies): Promise<void> {
    const url = `${this.apiUrl}/society/batch`;

    await this.http.post(url, { societies }).toPromise();
  }
}
