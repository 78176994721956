/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { RightHolder, RightHolderDTO } from 'app/shared/models/rightHolder';

export interface MessageAPI {
  id: string;
  text: string;
  ticketId: string;
  rightHolder: RightHolderDTO;
  updatedAt: string;
  createdAt: string;
}

export class Message implements Serializable<Message> {
  id: string;
  text: string;
  ticketId: string;
  rightHolder: RightHolder;
  updatedAt: string;
  createdAt: string;

  deserialize(input: MessageAPI): Message {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.text = input.text || '';
    this.ticketId = input.ticketId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.rightHolder = new RightHolder().deserialize(input.rightHolder) || null;

    return this;
  }

  deserializeArray(inputArray: Array<MessageAPI>): Array<Message> {
    const messages: Array<Message> = inputArray.map((input) => new Message().deserialize(input));

    return messages;
  }

  clone(): Message {
    const clonedInstance: Message = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
