/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { RightHolder, RightHolderDTO } from './rightHolder';

export enum UserStatusOptions {
  ACTIVE = 'active',
  LEAVE_PROCESS = 'leave process',
  COLLECTING = 'collecting',
  INACTIVE = 'inactive',
}

export interface UserDTO {
  id: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  nif: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  commissionBackground: number;
  commissionDigital: number;
  commissionLive: number;
  commissionRadioTV: number;
  status: string;
  stripeAccountId?: string;
  financialAdminId?: string;
  rightHolders?: RightHolderDTO[];
  rightHolder?: RightHolderDTO;
}

export class User implements Serializable<User> {
  id: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  nif: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  commissionBackground: number;
  commissionDigital: number;
  commissionLive: number;
  commissionRadioTV: number;
  status: string;
  // catalogs: Catalog[];
  // liquidations: Liquidation[];
  rightHolder: RightHolder;
  rightHolders: RightHolder[];

  clientType?: string;
  contractStartDay?: string;
  contractEndDay?: string;
  password?: string;

  constructor() {
    this.id = null;
    this.role = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.businessName = '';
    this.nif = '';
    this.address = '';
    this.city = '';
    this.postalCode = '';
    this.country = '';
    this.commissionBackground = 0;
    this.commissionDigital = 0;
    this.commissionLive = 0;
    this.commissionRadioTV = 0;
    this.status = UserStatusOptions.ACTIVE;
    this.rightHolder = null;
    this.rightHolders = [];
    this.clientType = '';
    this.contractStartDay = '';
    this.contractEndDay = '';
    this.password = '';
  }

  deserialize(input: UserDTO): User {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.role = input.role || '';
    this.email = input.email || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.businessName = input.businessName || '';
    this.nif = input.nif || '';
    this.address = input.address || '';
    this.city = input.city || '';
    this.postalCode = input.postalCode || '';
    this.country = input.country || '';
    this.commissionBackground = input.commissionBackground || 0;
    this.commissionDigital = input.commissionDigital || 0;
    this.commissionLive = input.commissionLive || 0;
    this.commissionRadioTV = input.commissionRadioTV || 0;
    this.status = input.status || '';

    if (input.rightHolder) {
      this.rightHolder = new RightHolder().deserialize(input.rightHolder);
    }

    this.rightHolders = input.rightHolders
      ? new RightHolder().deserializeArray(input.rightHolders)
      : [];

    return this;
  }

  deserializeArray(inputArray: Array<UserDTO>): Array<User> {
    const users: Array<User> = inputArray.map((input) => new User().deserialize(input));

    return users;
  }

  clone(): User {
    const clonedInstance: User = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
