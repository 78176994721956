/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Currency, CurrencyDTO } from './currency';
import { Claim, ClaimDTO } from './claim';
import { Liquidation, LiquidationDTO } from './liquidation';
import { Tax, TaxDTO } from './tax';

export enum StatementStatus {
  PENDING = 'payment_pending',
  SENT = 'sent',
  PAID = 'paid',
}

export class StatementDTO {
  id: string;
  description: string;
  senderName: string;
  senderNIF: string;
  senderAddress: string;
  senderCity: string;
  senderCountry: string;
  senderPostalCode: string;
  receiverName: string;
  receiverNIF: string;
  receiverAddress: string;
  receiverCity: string;
  receiverCountry: string;
  receiverPostalCode: string;
  subtotalAmount: number;
  totalAmount: number;
  status: StatementStatus;
  currencyId: string;
  resourceId: string;
  createdAt: string;
  updatedAt: string;
  expeditionDate: string;
  expirationDate: string;
  invoice: ClaimDTO;
  claim: ClaimDTO;
  liquidationUnison: LiquidationDTO;
  liquidationClient: LiquidationDTO;
  currency: CurrencyDTO;
  resource: string;
  taxes: Array<TaxDTO>;
  prefixId: string;
  numberId: number;
}

export class Statement implements Serializable<Statement> {
  id: string;
  description: string;
  senderName: string;
  senderNIF: string;
  senderAddress: string;
  senderCity: string;
  senderCountry: string;
  senderPostalCode: string;
  receiverName: string;
  receiverNIF: string;
  receiverAddress: string;
  receiverCity: string;
  receiverCountry: string;
  receiverPostalCode: string;
  subtotalAmount: number;
  expeditionDate: string;
  expirationDate: string;
  totalAmount: number;
  status: StatementStatus;
  currencyId: string;
  resourceId: string;
  createdAt: string;
  updatedAt: string;
  claim: Claim;
  liquidationUnison: Liquidation;
  liquidationClient: Liquidation;
  currency: Currency;
  resource: string;
  taxes: Array<Tax>;
  prefixId: string;
  numberId: number;

  deserialize(input: StatementDTO): Statement {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.description = input.description || '';
    this.senderName = input.senderName || '';
    this.senderNIF = input.senderNIF || '';
    this.senderAddress = input.senderAddress || '';
    this.senderCity = input.senderCity || '';
    this.senderCountry = input.senderCountry || '';
    this.senderPostalCode = input.senderPostalCode || '';
    this.receiverName = input.receiverName || '';
    this.receiverNIF = input.receiverNIF || '';
    this.receiverAddress = input.receiverAddress || '';
    this.receiverCity = input.receiverCity || '';
    this.receiverCountry = input.receiverCountry || '';
    this.receiverPostalCode = input.receiverPostalCode || '';
    this.subtotalAmount = input.subtotalAmount || 0;
    this.totalAmount = input.totalAmount || 0;
    this.status = input.status || StatementStatus.PENDING;
    this.currencyId = input.currencyId || '';
    this.resourceId = input.resourceId || '';
    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';
    this.resource = input.resource || '';
    this.expeditionDate = input.expeditionDate ? this.formatDate(input.expeditionDate) : '';
    this.expirationDate = input.expeditionDate ? this.formatDate(input.expeditionDate) : '';
    this.prefixId = input.prefixId || '';
    this.numberId = input.numberId || 0;

    this.liquidationUnison = input.liquidationUnison
      ? new Liquidation().deserialize(input.liquidationUnison)
      : null;
    this.liquidationClient = input.liquidationClient
      ? new Liquidation().deserialize(input.liquidationClient)
      : null;
    this.currency = new Currency().deserialize(input.currency);

    if (input.taxes) {
      this.taxes = new Tax().deserializeArray(input.taxes);
    }

    if (input.invoice) {
      this.claim = new Claim().deserialize(input.invoice);
    }

    if (input.claim) {
      this.claim = new Claim().deserialize(input.claim);
    }

    return this;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }

  get statementNumber(): string {
    const numberTo4Digits = `0000${this.numberId.toString()}`.slice(-4);

    return `${this.prefixId.toUpperCase()}${numberTo4Digits}`;
  }

  set statementNumber(statementNumber: string) {
    this.prefixId = statementNumber.slice(0, 3);
    this.numberId = parseInt(statementNumber.slice(-4), 10);
  }

  deserializeArray(inputArray: Array<StatementDTO>): Array<Statement> {
    const workUsages: Statement[] = inputArray.map((input) => new Statement().deserialize(input));

    return workUsages;
  }

  clone(): Statement {
    const clonedInstance: Statement = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
