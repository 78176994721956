import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { USAGE_REPORTS_MOCK } from 'app/shared/mocks';
import { UsageReport, UsageReportDTO } from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UsageReportService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getUsageReports(
    offset: number,
    limit: number,
    title: string | null = '',
  ): Promise<{ usageReports: UsageReport[]; info: InfoResponseAPI }> {
    let usageReportsFetched: UsageReport[] = [];
    let filters = `?offset=${offset}&limit=${limit}`;
    let info = {
      count: 0,
      offset: '',
      limit: '',
    };

    if (title !== '' && title !== null) {
      filters += `&name=${title}`;
    }

    if (environment.useMocks) {
      usageReportsFetched = new UsageReport().deserializeArray(USAGE_REPORTS_MOCK.usageReports);

      info = {
        count: usageReportsFetched.length,
        offset: '',
        limit: '',
      };
    } else {
      const url = `${this.apiUrl}/usage-report/${filters}`;
      const response = await this.http
        .get<{ usageReports: UsageReportDTO[]; info: InfoResponseAPI }>(url, httpOptions)
        .toPromise();
      const { usageReports } = response;

      info = response.info;
      usageReportsFetched = new UsageReport().deserializeArray(usageReports);
    }
    return { usageReports: usageReportsFetched, info };
  }

  async downloadReportUsageAsCSV(reportUsageId: string): Promise<any> {
    const url = `${this.apiUrl}/usage-report/${reportUsageId}/csv?cShares=true`;

    return this.http.get(url).toPromise();
  }
}
