import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import {
  Liquidation,
  LiquidationDTO,
  StatementStatus,
  UsageShare,
  UsageTypes,
} from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';

export interface LiquidationsResponse {
  liquidations: Liquidation[];
  info: InfoResponseAPI;
}

export interface LiquidationsFilterI {
  status?: StatementStatus | '';
  type?: UsageTypes | '';
  dateFrom?: string;
  dateTo?: string;
}

@Injectable()
export class LiquidationService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private readonly liquidationSubject = new BehaviorSubject<Liquidation>(null);
  readonly currentLiquidation$ = this.liquidationSubject.asObservable();

  get currentLiquidation(): Liquidation {
    return this.liquidationSubject.getValue();
  }

  set currentLiquidation(liquidation: Liquidation) {
    this.liquidationSubject.next(liquidation);
  }

  async getLiquidations(
    offset: number,
    limit: number,
    filters?: LiquidationsFilterI,
  ): Promise<LiquidationsResponse> {
    const url = `${this.apiUrl}/liquidation/`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      ...filters,
    };

    const { liquidations: LiquidationsApi, info } = await this.http
      .get<{ liquidations: Array<LiquidationDTO>; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      liquidations: new Liquidation().deserializeArray(LiquidationsApi),
      info,
    };
  }

  async getLiquidation(liquidationId: string): Promise<Liquidation> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/`;

    const { liquidation: liquidationApi } = await this.http
      .get<{ liquidation: LiquidationDTO }>(url)
      .toPromise();

    return new Liquidation().deserialize(liquidationApi);
  }

  async updateLiquidation(liquidation: Liquidation): Promise<Liquidation> {
    const url = `${this.apiUrl}/liquidation/${liquidation.id}/`;

    const { liquidation: liquidationAPI } = await this.http
      .put<{ liquidation: LiquidationDTO }>(url, liquidation)
      .toPromise();

    return new Liquidation().deserialize(liquidationAPI);
  }

  async createLiquidations(
    userId: string,
    dateFrom: string,
    dateTo: string,
    usages: Array<UsageShare>,
  ): Promise<Array<Liquidation>> {
    const usagesIds: Array<string> = usages.map((usage) => usage.id);
    const url = `${this.apiUrl}/liquidation`;

    const { liquidations: liquidationsApi } = await this.http
      .post<{ liquidations: Array<LiquidationDTO> }>(url, { userId, dateFrom, dateTo, usagesIds })
      .toPromise();

    return new Liquidation().deserializeArray(liquidationsApi);
  }

  async deleteLiquidation(liquidationdId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationdId}/`;

    await this.http.delete<void>(url).toPromise();
  }

  async deleteLiquidationUsage(liquidationId: string, usageId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/usage/${usageId}`;

    await this.http.delete<void>(url).toPromise();
  }

  // updateLiquidationStatus(id, data) {
  //   const url = `${this.apiUrl}/liquidation/${id}/status`;
  //   return this.http.put(url, data).pipe(
  //     tap(() => this.log(`updated status from liquidation with id = ${id}`)),
  //     catchError(this.handleError),
  //   );
  // }

  // generateLiquidations() {
  //   const url = `${this.apiUrl}/liquidation/emit/`;
  //   // Canviar per post
  //   return this.http.post(url, null).pipe(
  //     tap(() => this.log('generated liquidations')),
  //     catchError(this.handleError),
  //   );
  // }
}
