/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { CollectionShare, CollectionShareDTO } from './collectionShare';
import { RecordingUsage, RecordingUsageDTO } from './recordingUsage';
import { Share, ShareDTO } from './share';
import { WorkUsage, WorkUsageDTO } from './workUsage';

export enum UsageShareStatus {
  LIQUIDATED = 'liquidated',
  PAYMENT_PENDING = 'payment_pending',
  LIQUIDATION_PENDING = 'liquidation_pending',
}

export interface UsageShareDTO {
  id: string;
  catalogId: string;
  shareId: string;
  share: ShareDTO;
  compositionUsageId: string;
  compositionUsage: WorkUsageDTO;
  recordingUsageId: string;
  recordingUsage: RecordingUsageDTO;
  shareLiquidationId: string;
  split: number;
  status: UsageShareStatus;
  userId: string;
  amount: number;
  createdAt: string;
}

export class UsageShare implements Serializable<UsageShare> {
  id: string;
  catalogId: string;
  compositionUsageId: string;
  compositionUsage: WorkUsage;
  recordingUsageId: string;
  recordingUsage: RecordingUsage;
  split: number;
  status: UsageShareStatus;
  userId: string;
  amount: number;
  shareId: string;
  share: Share;
  createdAt: string;

  deserialize(input: UsageShareDTO): UsageShare {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.status = input.status || UsageShareStatus.LIQUIDATED;
    this.compositionUsageId = input.compositionUsageId || '';
    this.catalogId = input.catalogId || '';
    this.split = input.split || 0;
    this.userId = input.userId || '';
    this.amount = input.amount || 0;
    this.shareId = input.shareId || '';
    this.share = input.share ? new Share().deserialize(input.share) : null;
    this.createdAt = input.createdAt || '';
    this.compositionUsage = input.compositionUsage
      ? new WorkUsage().deserialize(input.compositionUsage)
      : null;
    this.recordingUsage = input.recordingUsage
      ? new RecordingUsage().deserialize(input.recordingUsage)
      : null;

    return this;
  }

  deserializeArray(inputArray: Array<UsageShareDTO>): Array<UsageShare> {
    const usageShares: Array<UsageShare> = inputArray.map((input) =>
      new UsageShare().deserialize(input),
    );

    return usageShares;
  }

  clone(): UsageShare {
    const clonedInstance: UsageShare = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
