<ng-template #progressModal let-modal>

  <div class="modal-header justify-content-center" *ngIf="modalInfo?.title">
    <h5 class="modal-title" id="staticBackdropLabel">
      {{ modalInfo.title }}
    </h5>
  </div>
  <div class="modal-body">
    <div class="progress" *ngIf="modalInfo?.currentProgress >= 0">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ 'width.%': modalInfo.currentProgress }"
      >
        <!-- style="width: 75%" -->
        {{ modalInfo.currentProgress }} %
      </div>
    </div>
    <!-- <div class="progress-bar text-center">{{ modalInfo.progress }} %</div> -->
    <p class="text-center mt-3" *ngIf="modalInfo?.statusText">
      {{ modalInfo.statusText }}
    </p>
  </div>
  <div class="modal-footer" *ngIf="showFooter()">
    <button
      type="button"
      class="btn btn-unison"
      *ngIf="modalInfo.showCancelButton"
      (click)="emitCancel()"
    >
      {{ "Cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn ml-2 btn-unison"
      (click)="modal.close('Close click')"
      *ngIf="modalInfo.showCloseButton"
    >
      {{ "Close" | translate }}
    </button>
  </div>
</ng-template>

