import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

import { CurrenciesAPIResponse } from 'app/shared/interfaces';

import { Currency } from 'app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getCurrencies(offset = 0, limit = 9999): Promise<Array<Currency>> {
    let currenciesFetched: Array<Currency> = [];
    const filters = `?offset=${offset}&limit=${limit}`;
    const url = `${this.apiUrl}/currency/${filters}`;

    try {
      const response: CurrenciesAPIResponse = await this.http
        .get<CurrenciesAPIResponse>(url)
        .toPromise();
      currenciesFetched = new Currency().deserializeArray(response.currencies);
    } catch (error) {
      // console.log("UserService -> error", error)
    }

    return currenciesFetched;
  }
}
