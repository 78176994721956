import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { Claim, ClaimDTO, StatementStatus, Usage } from 'app/shared/models';

export interface ClaimsResponse {
  claims: Claim[];
  info: InfoResponseAPI;
}

export interface ClaimsFilterI {
  status?: StatementStatus | '';
  from?: string;
  to?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private readonly claimSubject = new BehaviorSubject<Claim>(null);
  readonly currentClaim$ = this.claimSubject.asObservable();

  get currentClaim(): Claim {
    return this.claimSubject.getValue();
  }

  set currentClaim(claim: Claim) {
    this.claimSubject.next(claim);
  }

  async getClaims(offset: number, limit: number, filters?: ClaimsFilterI): Promise<ClaimsResponse> {
    const queryString = `?offset=${offset}&limit=${limit}&status=${filters?.status}&dateFrom=${filters?.from}&dateTo=${filters?.to}`;

    const url = `${this.apiUrl}/invoice/${queryString}`;

    const { invoices: claimsApi, info } = await this.http
      .get<{ invoices: Array<ClaimDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      claims: new Claim().deserializeArray(claimsApi),
      info,
    };
  }

  async getClaim(claimId: string): Promise<Claim> {
    const url = `${this.apiUrl}/invoice/${claimId}/`;

    const { invoice: claimApi } = await this.http.get<{ invoice: ClaimDTO }>(url).toPromise();

    return new Claim().deserialize(claimApi);
  }

  async createClaim(
    consumerId: string,
    dateFrom: string,
    dateTo: string,
    usages: Array<Usage> | null,
  ): Promise<Claim | null> {
    const url = `${this.apiUrl}/consumer/${consumerId}/generate-invoice`;

    const params = {
      dateFrom,
      dateTo,
      usagesIds: usages ? usages.map((usage) => usage.id) : undefined,
    };

    const { invoice: claimApi } = await this.http
      .post<{ invoice: ClaimDTO }>(url, params)
      .toPromise();

    if (!claimApi) {
      return null;
    }

    return new Claim().deserialize(claimApi);
  }

  async emitPendingClaims(consumerId: string, dateFrom: string, dateTo: string): Promise<void> {
    const url = `${this.apiUrl}/consumer/${consumerId}/generate-invoice`;

    await this.http
      .post<{ invoice: ClaimDTO }>(url, { dateFrom, dateTo })
      .toPromise();
  }

  async updateClaim(claim: Claim): Promise<Claim> {
    const url = `${this.apiUrl}/invoice/${claim.id}/`;

    const { invoice: claimApi } = await this.http
      .put<{ invoice: ClaimDTO }>(url, claim)
      .toPromise();

    return new Claim().deserialize(claimApi);
  }

  async deleteClaim(claimdId: string): Promise<void> {
    const url = `${this.apiUrl}/invoice/${claimdId}/`;

    await this.http.delete<void>(url).toPromise();
  }

  async deleteClaimUsage(claimdId: string, usageId: string): Promise<void> {
    const url = `${this.apiUrl}/invoice/${claimdId}/usage/${usageId}`;

    await this.http.delete<void>(url).toPromise();
  }
}
