import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Recording, RecordingDTO } from 'app/shared/models';
import { RECORDINGS_WORK_MOCK } from 'app/shared/mocks';
import { InfoResponseAPI } from '../interfaces';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

export interface RecordingsResponse {
  recordings: Recording[];
  info: InfoResponseAPI;
}

@Injectable({
  providedIn: 'root',
})
export class RecordingService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getRecordings(offset: number, limit: number, text: string): Promise<RecordingsResponse> {
    const filters = `?offset=${offset}&limit=${limit}&text=${text}`;
    const url = `${this.apiUrl}/recording/${filters}`;

    const { recordings: recordingsAPI, info } = await this.http
      .get<{ recordings: Array<RecordingDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      recordings: new Recording().deserializeArray(recordingsAPI),
      info,
    };
  }

  async getRecording(recordingId: string): Promise<Recording> {
    let recordingFetched: Recording;
    if (environment.useMocks) {
      const recordingMock = RECORDINGS_WORK_MOCK.filter(
        (recording: RecordingDTO) => recording.id === recordingId,
      );
      recordingFetched = new Recording().deserialize(recordingMock[0]);
    } else {
      const url = `${this.apiUrl}/recording/${recordingId}/`;

      const { recording } = await this.http.get<{ recording: RecordingDTO }>(url).toPromise();
      recordingFetched = new Recording().deserialize(recording);
    }
    return recordingFetched;
  }

  async createRecording(recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/recording/`;

    const { recording: recordingAPI } = await this.http
      .post<{ recording: RecordingDTO }>(url, recording)
      .toPromise();

    return new Recording().deserialize(recordingAPI);
  }

  async updateRecording(recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/recording/${recording.id}/`;

    const { recording: recordingAPI } = await this.http
      .put<{ recording: RecordingDTO }>(url, recording)
      .toPromise();

    return new Recording().deserialize(recordingAPI);
  }

  async deleteRecording(recordingId: string): Promise<void> {
    const url = `${this.apiUrl}/recording/${recordingId}/`;

    await this.http.delete(url).toPromise();
  }

  async getRecordingsFromWork(workId: string): Promise<Array<Recording>> {
    let recordingsFetched: Array<Recording> = [];
    if (environment.useMocks) {
      recordingsFetched = new Recording().deserializeArray(RECORDINGS_WORK_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/recording/`;
      const response = await this.http
        .get<{ recordings: Array<RecordingDTO> }>(url, httpOptions)
        .toPromise();
      recordingsFetched = new Recording().deserializeArray(response.recordings);
    }

    return recordingsFetched;
  }

  async addRecordingToWork(workId: string, recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/composition/${workId}/recording/`;
    const response = await this.http.post<{ recording: RecordingDTO }>(url, recording).toPromise();
    return new Recording().deserialize(response.recording);
  }

  async deleteRecordingFromWork(workId: string, recordingId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/recording/${recordingId}/`;
    await this.http.delete(url).toPromise();
  }
}
