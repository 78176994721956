import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// SimpleChanges,

export interface ModalInfoInterface {
  title?: string;
  statusText?: string;
  currentProgress?: number;
  show?: boolean;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
}

@Component({
  selector: 'app-modal-progress',
  templateUrl: './modal-progress.component.html',
  styleUrls: ['./modal-progress.component.scss'],
})
export class ModalProgressComponent implements OnInit, OnChanges {
  @Input() modalInfo: ModalInfoInterface;
  @Output() cancelAction = new EventEmitter<boolean>();

  @ViewChild('progressModal') progressModal: TemplateRef<HTMLElement>;

  modal: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  // ngOnChanges(changes: SimpleChanges): void {
  ngOnChanges(): void {
    if (!this.modalInfo) {
      return;
    }

    if (this.modalInfo.show) {
      this.openModal(this.progressModal);
    } else {
      this.closeModal();
    }
  }

  openModal(content: TemplateRef<HTMLElement>, size: 'lg' | 'xl' = 'lg'): void {
    if (!this.modal) {
      this.modal = this.modalService.open(content, { size });
    }
  }

  closeModal(): void {
    if (this.modal) {
      this.modal.close();
      this.modal = null;
    }
  }

  emitCancel(): void {
    this.cancelAction.emit(true);
  }

  showFooter(): boolean {
    if (!this.modalInfo) {
      return false;
    }

    return this.modalInfo.showCancelButton || this.modalInfo.showCloseButton;
  }
}
