import { Serializable } from 'app/core/interfaces';

export interface RateDTO {
  id: string;
  title: string;
  dawningRate: number;
  morningRate: number;
  noonRate: number;
  afternoonRate: number;
  primeTimeRate: number;
  lateNightRate: number;
  defaultRate: boolean;
}

export class Rate implements Serializable<Rate> {
  id: string;
  title: string;
  dawningRate: number;
  morningRate: number;
  noonRate: number;
  afternoonRate: number;
  primeTimeRate: number;
  lateNightRate: number;
  defaultRate: boolean;

  deserialize(input: RateDTO): Rate {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.title = input.title || '';
    this.dawningRate = input.dawningRate || 0;
    this.morningRate = input.morningRate || 0;
    this.noonRate = input.noonRate || 0;
    this.afternoonRate = input.afternoonRate || 0;
    this.primeTimeRate = input.primeTimeRate || 0;
    this.lateNightRate = input.lateNightRate || 0;
    this.defaultRate = input.defaultRate || false;

    return this;
  }

  deserializeArray(inputArray: Array<RateDTO>): Array<Rate> {
    const ratesArray: Array<Rate> = inputArray.map((input) => new Rate().deserialize(input));

    return ratesArray;
  }

  clone(): Rate {
    const clonedInstance: Rate = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
