import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';

import { Catalog, CatalogDTO, Work, WorkDTO } from 'app/shared/models';
import {
  CatalogsAPIResponseI,
  CatalogsResponseI,
  InfoResponseAPI,
  OrderTableI,
} from 'app/shared/interfaces';
import { CATALOGS_USER_MOCK, WORKS_ALL_MOCK } from 'app/shared/mocks';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const CATALOG_TIMEOUT = 300000;

export interface CatalogFiltersI extends OrderTableI {
  title?: string;
  entryDate?: string;
  owner?: string;
  status?: string;
  author?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  apiUrl: string = environment.apiUrl;

  private readonly catalogSubject = new BehaviorSubject<Catalog>(null);
  readonly currentCatalog$ = this.catalogSubject.asObservable();

  get currentCatalog(): Catalog {
    return this.catalogSubject.getValue();
  }

  set currentCatalog(catalog: Catalog) {
    this.catalogSubject.next(catalog);
  }

  constructor(private http: HttpClient) {}

  async getCatalogs(
    offset: number,
    limit: number,
    filters?: CatalogFiltersI,
  ): Promise<CatalogsResponseI> {
    let catalogsFetched: Catalog[] = [];
    let params = `?offset=${offset}&limit=${limit}`;
    let info = {
      count: 0,
      offset,
      limit,
    };

    if (filters?.title) {
      params += `&name=${filters?.title}`;
    }

    if (filters?.entryDate) {
      params += `&entryDate=${filters?.entryDate}`;
    }

    if (filters?.owner) {
      params += `&owner=${filters?.owner}`;
    }

    if (filters?.orderType) {
      params += `&orderType=${filters?.orderType}`;
    }

    if (filters?.orderField) {
      params += `&orderField=${filters?.orderField}`;
    }

    if (environment.useMocks) {
      catalogsFetched = new Catalog().deserializeArray(CATALOGS_USER_MOCK);

      info = {
        count: catalogsFetched.length,
        offset,
        limit,
      };
    } else {
      const url = `${this.apiUrl}/catalog/${params}`;
      const response: CatalogsAPIResponseI = await this.http
        .get<CatalogsAPIResponseI>(url, httpOptions)
        .toPromise();
      const { catalogs } = response;

      info = response.info;
      catalogsFetched = new Catalog().deserializeArray(catalogs);
    }
    return { catalogs: catalogsFetched, info };
  }

  async getCatalog(catalogId: string): Promise<Catalog> {
    const url = `${this.apiUrl}/catalog/${catalogId}/`;
    let catalogfetched: Catalog = null;

    if (environment.useMocks) {
      const catalog: CatalogDTO = CATALOGS_USER_MOCK.find((c: CatalogDTO) => c.id === catalogId);
      catalogfetched = new Catalog().deserialize(catalog);
    } else {
      const { catalog: catalogApi } = await this.http
        .get<{ catalog: CatalogDTO }>(url, httpOptions)
        .toPromise();

      catalogfetched = new Catalog().deserialize(catalogApi);
    }
    return catalogfetched;
  }

  async createCatalog(data): Promise<Catalog> {
    const url = `${this.apiUrl}/catalog/`;
    const { catalog } = await this.http.post<{ catalog: CatalogDTO }>(url, data).toPromise();

    return new Catalog().deserialize(catalog);
  }

  async updateCatalog(catalogToUpdate: Catalog): Promise<Catalog> {
    const url = `${this.apiUrl}/catalog/${catalogToUpdate.id}/`;

    const { catalog } = await this.http
      .put<{ catalog: CatalogDTO }>(url, catalogToUpdate)
      .toPromise();

    return new Catalog().deserialize(catalog);
  }

  async deleteCatalog(catalogId: string): Promise<void> {
    const url = `${this.apiUrl}/catalog/${catalogId}/`;

    await this.http.delete<void>(url).toPromise();
  }

  async disableCatalog(catalog: Catalog): Promise<void> {
    const url = `${this.apiUrl}/catalog/${catalog.id}/disable`;
    await this.http.put(url, catalog).toPromise();
  }

  createCSVCatalog(json: any): Observable<any> {
    const url = `${this.apiUrl}/catalog/csv`;
    return this.http.post(url, json).pipe(
      timeout(CATALOG_TIMEOUT),
      catchError((error) => {
        return this.handleError({ ...error, originalSongs: json });
      }),
    );
  }

  createCWRCatalog(json: any): Observable<any> {
    const url = `${this.apiUrl}/catalog/cwr`;
    return this.http.post(url, { cwr: json }).pipe(
      timeout(CATALOG_TIMEOUT),
      catchError((error) => {
        return this.handleError({ ...error, originalSongs: json });
      }),
    );
  }

  downloadCatalogAsCSV(catalogId: string): Observable<any> {
    const url = `${this.apiUrl}/catalog/csv/`;

    return this.http.get(url, {
      observe: 'events', // 'body'
      params: { cShares: 'true', ids: [catalogId] },
      responseType: 'blob',
      reportProgress: true,
    });
  }

  downloadCatalogAsCWR(catalogId: string): Observable<any> {
    const url = `${this.apiUrl}/catalog/${catalogId}/cwr`;

    return this.http.get(url, {
      observe: 'events', // 'body'
      params: { cShares: 'true' },
      responseType: 'blob',
      reportProgress: true,
    });
  }

  async getWorksFromCatalog(
    offset: number,
    limit: number,
    id: string,
    filters?: CatalogFiltersI,
  ): Promise<{ works: Work[]; info: InfoResponseAPI }> {
    let works: Work[];
    let info: InfoResponseAPI;

    if (environment.useMocks) {
      works = new Work().deserializeArray(WORKS_ALL_MOCK);
      info = {
        count: works.length,
        offset: '0',
        limit: works.length.toString(),
      };
    } else {
      let query = `?offset=${offset}&limit=${limit}&catalog=${id}`;
      if (filters && filters.status) {
        query += `&status=${filters.status}`;
      }
      if (filters && filters.author) {
        query += `&author=${filters.author}`;
      }
      if (filters && filters.title) {
        query += `&title=${filters.title}`;
      }
      if (filters && filters.orderType) {
        query += `&orderType=${filters.orderType}`;
      }
      if (filters && filters.orderField) {
        query += `&orderField=${filters.orderField}`;
      }
      // const url = `${this.apiUrl}/composition/${query}`;

      // TODO review query
      const url = `${this.apiUrl}/catalog/${id}/work/${query}`;

      const { works: worksApi, info: infoAPI } = await this.http
        .get<{ works: WorkDTO[]; info: InfoResponseAPI }>(url, httpOptions)
        .toPromise();

      works = new Work().deserializeArray(worksApi);
      info = infoAPI;
    }

    return {
      works,
      info,
    };
  }

  async addWorkToCatalog(
    catalogId: string,
    workId: string,
  ): Promise<{ addedWorks: Array<string>; unaddedWorks: Array<string> }> {
    const url = `${this.apiUrl}/catalog/${catalogId}/work/${workId}`;

    const { addedWorks, unaddedWorks } = await this.http
      .post<{ addedWorks: Array<string>; unaddedWorks: Array<string> }>(url, httpOptions)
      .toPromise();

    return {
      addedWorks,
      unaddedWorks,
    };
  }

  async deleteWorkFromCatalog(catalogId: string, workId: string): Promise<void> {
    const url = `${this.apiUrl}/catalog/${catalogId}/work/${workId}`;
    await this.http.delete(url, httpOptions).toPromise();
  }

  async getCatalogRoles(): Promise<string[]> {
    const url = `${this.apiUrl}/catalog/roles`;

    const { roles } = await this.http.get<{ roles: string[] }>(url, httpOptions).toPromise();
    return roles;
  }

  private handleError(error: any): Observable<any> {
    // eslint-disable-next-line no-console
    console.error('An error occurred', error);
    return observableThrowError(error);
  }

  private log(message: string): void {
    // eslint-disable-next-line no-console
    console.log(`CatalogService: ${message}`);
  }
}
