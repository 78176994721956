<nav class="navbar navbar-expand-lg navbar-light px-0">
  <div class="ml-auto">
    <ul class="nav right-nav">
      <li class="nav-item">
        <div ngbDropdown class="btn-group">
          <button
            type="button"
            class="btn btn-logout white dropdown-toggle"
            ngbDropdownToggle
            id="dropdownUser"
            data-toggle="dropdown"
          >
            <fa-icon [icon]="faUser" class="mr-2"></fa-icon>
            {{ user.email }}
            <i class="fas fa-user"></i>
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownUser"
            class="dropdown-menu dropdown-menu-right"
          >
            <a ngbDropdownItem class="dropdown-item" routerLink="/profile/">
              {{ 'Profile' | translate }}
            </a>
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem type="button" class="dropdown-item" (click)="logout()">
              {{ 'Log out' | translate }}
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
