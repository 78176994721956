import { WorkDTO } from 'app/shared/models';

export const TOP_WORKS_USAGES_GENERAL: WorkDTO[] = [
  {
    id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
    unisonId: 'CO1V029Q184C',
    title: 'FLEEING CHANCES',
    iswc: 'T2649413281',
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-25T10:43:18.354Z',
    submitterWorkN: null,
    copyrightNumber: null,
    musicalWorkDistributionCategory: null,
    duration: null,
    recordedIndicator: null,
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: null,
    datePublicationPrintedEdition: null,
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: null,
    createdAt: '2020-06-25T10:43:20.378Z',
    updatedAt: '2020-06-25T10:43:20.378Z',
    createdBy: null,
    language: 'EN',
    source: 'Live',
    consumer: 'Lorem ipsum 1',
    usages: 25000,
    amount: 3000,
  },
  {
    id: '96276ef0-6ad9-48bd-af2d-c18a87a5ee77',
    unisonId: 'COGDX06BM9KM',
    title: 'THE MAD SOUNDS OF CONFLICT   THE CONFRATERNITY OF',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:34:55.509Z',
    submitterWorkN: '0001226',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:34:55.510Z',
    updatedAt: '2020-06-26T11:34:55.558Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: 'EN',
    source: 'Digital',
    consumer: 'Lorem ipsum 2',
    usages: 23000,
    amount: 2800,
  },
  {
    id: '316ba330-6d0c-4f1d-917e-b1ac8a8e4626',
    unisonId: 'COM4JBN2OEDA',
    title: 'I WANNA LIE  WHERE THE FLOWERS GROW',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:34:56.208Z',
    submitterWorkN: '0001236',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:34:56.209Z',
    updatedAt: '2020-06-26T11:34:56.277Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: 'EN',
    source: 'TV - Radio',
    consumer: 'Lorem ipsum 3',
    usages: 20000,
    amount: 2000,
  },
  {
    id: '38b57c44-2670-4cb1-870e-e2dd24011f17',
    unisonId: 'CONRA5CCBURU',
    title: 'WHEN A GHOST FLIES YOUNG',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:34:56.508Z',
    submitterWorkN: '0001240',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:34:56.509Z',
    updatedAt: '2020-06-26T11:34:56.565Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: 'EN',
    source: 'TV - Radio',
    consumer: 'Lorem ipsum 4',
    usages: 18000,
    amount: 1800,
  },
  {
    id: '7dec9c49-906b-4a9f-a832-853029ff2917',
    unisonId: 'COGFB7ZZSKZ2',
    title: 'FLYING MACHINE BLUES',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:34:56.688Z',
    submitterWorkN: '0001243',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:34:56.690Z',
    updatedAt: '2020-06-26T11:34:56.725Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: 'EN',
    source: 'Digital',
    consumer: 'Lorem ipsum 5',
    usages: 15000,
    amount: 1500,
  },
  {
    id: '06b6ab38-b68a-4d41-bc06-53ff6195410f',
    unisonId: 'COACG5FQ2H5T',
    title: 'BEIJA FLOR APAIXONADO',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:35:29.914Z',
    submitterWorkN: '0001055',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:35:29.916Z',
    updatedAt: '2020-06-26T11:35:29.957Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: null,
    source: 'Background',
    consumer: 'Lorem ipsum 6',
    usages: 12000,
    amount: 1200,
  },
  {
    id: 'ba9fea2f-1a11-4bb3-97ba-68e47e0d5b8e',
    unisonId: 'COARWDH6C2BZ',
    title: 'BEIJANDO A FLOR',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:35:29.965Z',
    submitterWorkN: '0001056',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:35:29.966Z',
    updatedAt: '2020-06-26T11:35:30.007Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: null,
    source: '',
    consumer: '',
    usages: 0,
    amount: 0,
  },
  {
    id: 'afffe2d6-3016-4b22-a505-e6b0705a07ec',
    unisonId: 'COUXBJNVLNRR',
    title: 'FLOR DE TANGERINA',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:35:33.307Z',
    submitterWorkN: '0001116',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:35:33.308Z',
    updatedAt: '2020-06-26T11:35:33.349Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: null,
    source: '',
    consumer: '',
    usages: 0,
    amount: 0,
  },
  {
    id: '931736af-6a8d-408b-8144-d377aa2044a0',
    unisonId: 'COHNEIGSF92T',
    title: 'A TELEFONISTA NA FLORESTA PREDIAL',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:35:33.042Z',
    submitterWorkN: '0069861',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:35:33.044Z',
    updatedAt: '2020-06-26T11:35:33.088Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: null,
    source: '',
    consumer: '',
    usages: 0,
    amount: 0,
  },
  {
    id: 'ee47415f-f08c-4916-9c96-ed2424e9ed03',
    unisonId: 'CO8FLFOGQA71',
    title: 'A ALMA AFOITA DA REVOLUCAO',
    iswc: null,
    type: 'MTX',
    versionType: 'ORI',
    status: 'ACCEPTED',
    musicArrangement: null,
    lyricAdaptation: null,
    copyrightDate: '2020-06-26T11:35:32.626Z',
    submitterWorkN: '0069749',
    copyrightNumber: null,
    musicalWorkDistributionCategory: 'UNC',
    duration: '00:00:00',
    recordedIndicator: 'Y',
    compositeType: null,
    excerptType: null,
    contactName: null,
    contactId: null,
    workType: null,
    grandRightsIndicator: null,
    compositeComponentCount: 0,
    datePublicationPrintedEdition: '0000-00-00',
    exceptionalClause: null,
    opusNumber: null,
    catalogueNumber: null,
    priorityFlag: 'Y',
    createdAt: '2020-06-26T11:35:32.628Z',
    updatedAt: '2020-06-26T11:35:32.678Z',
    createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
    language: null,
    source: '',
    consumer: '',
    usages: 0,
    amount: 0,
  },
];
