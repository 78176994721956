import { RecordingDTO } from 'app/shared/models';
import { WORKS_ALL_MOCK } from './works-all-mock';

export const RECORDINGS_WORK_MOCK: Array<RecordingDTO> = [
  {
    title: 'ENOUGH',
    isrc: 'NLCK41077303',
    compositionId: '0013eecf-ec9b-4455-ab94-756b1e1b5c75',
    firstReleaseDate: null,
    firstReleaseDuration: null,
    firstAlbumTitle: 'TRENDY MUSIC VOL.4',
    firstAlbumLabel: null,
    firstReleaseCatalogNumber: null,
    ean: null,
    recordingFormat: null,
    recordingTechnique: null,
    mediaType: null,
    composition: WORKS_ALL_MOCK[0],
    id: '6aa27799-c7ba-4f71-a60a-2bed2163d179',
    unisonId: 'REBGBHFOIZJV',
    performers: [
      {
        id: '1e3be511-5de1-4886-9b11-5fe5b7ca47a5',
        firstName: null,
        lastName: 'ALICIA ZILLA AND FRESH MINT SWEETS',
        ipi: null,
        ipiBase: null,
        createdAt: '2020-11-26T12:52:56.583Z',
        updatedAt: '2020-11-26T12:52:56.583Z',
        RecordingPerformer: {
          id: '8df9a402-6274-4dac-93db-7cc764e2fec9',
          createdAt: '2020-11-26T12:52:56.593Z',
          updatedAt: '2020-11-26T12:52:56.593Z',
          recordingId: '6aa27799-c7ba-4f71-a60a-2bed2163d179',
          performerId: '1e3be511-5de1-4886-9b11-5fe5b7ca47a5',
        },
      },
    ],
  },
];
