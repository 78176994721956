import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Ticket, TicketDTO, TicketStatus } from 'app/shared/models/ticket';
import { TICKETS_MOCK_ME } from 'app/shared/mocks';
import {
  InfoResponseAPI,
  MessageAddedResponseAPII,
  MessageI,
  TicketFiltersI,
  TicketResponseAPII,
  TicketsResponse,
} from 'app/shared/interfaces';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getTickets(
    offset: number,
    limit: number,
    filtersToApply: TicketFiltersI,
  ): Promise<TicketsResponse> {
    let filters = `?offset=${offset}&limit=${limit}`;

    if (filtersToApply.text) {
      filters += `&text=${filtersToApply.text}`;
    }
    if (filtersToApply.status) {
      filters += `&status=${filtersToApply.status}`;
    }

    const url = `${this.apiUrl}/ticket/${filters}`;
    const { tickets, info } = await this.http
      .get<{ tickets: TicketDTO[]; info: InfoResponseAPI }>(url, httpOptions)
      .toPromise();

    return {
      tickets: new Ticket().deserializeArray(tickets),
      info,
    };
  }

  async getTicket(ticketId: string): Promise<Ticket> {
    let ticketFetched: Ticket = new Ticket();
    if (environment.useMocks) {
      const allTickets = new Ticket()
        .deserializeArray(TICKETS_MOCK_ME.tickets)
        .filter((ticket: Ticket) => {
          if (!ticketId) {
            return true;
          }

          return ticket.id.includes(ticketId);
        });
      [ticketFetched] = allTickets;
    } else {
      const url = `${this.apiUrl}/ticket/${ticketId}/`;
      const response: TicketResponseAPII = await this.http
        .get<TicketResponseAPII>(url, httpOptions)
        .toPromise();
      ticketFetched = new Ticket().deserialize(response.ticket);
    }
    return ticketFetched;
  }

  async createTicket(ticket: Ticket): Promise<Ticket> {
    const url = `${this.apiUrl}/ticket/`;

    const { ticket: ticketAPI }: TicketResponseAPII = await this.http
      .post<{ ticket: TicketDTO }>(url, ticket, httpOptions)
      .toPromise();

    return new Ticket().deserialize(ticketAPI);
  }

  async deleteTicket(ticketId: string): Promise<void> {
    const url = `${this.apiUrl}/ticket/${ticketId}/`;

    await this.http.delete(url).toPromise();
  }

  async addMessageToTicket(ticketId: string, message: MessageI): Promise<void> {
    if (environment.useMocks) {
      // console.log(message);
    } else {
      try {
        const url = `${this.apiUrl}/ticket/${ticketId}/message/`;
        // const response: MessageAddedResponseAPII = await this.http.post<MessageAddedResponseAPII>(url, message, httpOptions).toPromise();
        await this.http.post<MessageAddedResponseAPII>(url, message, httpOptions).toPromise();
      } catch (error) {
        // console.log(error);
      }
    }
  }

  async updateTicketStatus(ticketId: string, ticketStatus: TicketStatus): Promise<void> {
    const url = `${this.apiUrl}/ticket/${ticketId}/status`;
    await this.http.put(url, { status: ticketStatus }).toPromise();
  }
}
