<div class="row app-header d-flex justify-content-between">
  <!-- <div class="col-12 col-lg-2 order-2 order-lg-1"> -->
  <!-- <app-breadcrumbs></app-breadcrumbs> -->
  <!-- <div class="white">LOGO</div>
  </div>
  <div class="col-12 offset-lg-7 col-lg-3 order-1 order-lg-2 app-header-menu">
    <app-header-menu></app-header-menu>
  </div> -->
  <div class="app-sidebar-top">
    <a routerLink="/">
      <img class="logo" src="/assets/images/logo-unison-white.png" alt="Unison" />
    </a>
  </div>
  <fa-icon class="sidebar-toggler-open show d-lg-none" [icon]="faBars" appSidebarToggler></fa-icon>
  <fa-icon class="sidebar-toggler-close d-lg-none" [icon]="faTimes" appSidebarToggler></fa-icon>
  <app-header-menu></app-header-menu>
</div>
