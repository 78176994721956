import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Statement, StatementDTO, Tax, TaxDTO } from 'app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class StatementService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getStatement(statementId: string): Promise<Statement> {
    const url = `${this.apiUrl}/statement/${statementId}/`;

    const { statement } = await this.http.get<{ statement: StatementDTO }>(url).toPromise();

    return new Statement().deserialize(statement);
  }

  async downloadStatementPDF(statement: Statement): Promise<Statement> {
    const url = `${this.apiUrl}/statement/${statement.id}/pdf`;

    throw new Error('Function not implemented');
  }

  async updateStatement(statement: Statement): Promise<Statement> {
    const url = `${this.apiUrl}/statement/${statement.id}/`;

    const { statement: statementAPI } = await this.http
      .put<{ statement: StatementDTO }>(url, statement)
      .toPromise();

    return new Statement().deserialize(statementAPI);
  }

  async deleteStatement(statementId: string): Promise<void> {
    const url = `${this.apiUrl}/statement/${statementId}/`;

    await this.http.delete(url).toPromise();
  }

  async createStatementTax(statementId: string, tax: Tax): Promise<Tax> {
    const url = `${this.apiUrl}/statement/${statementId}/tax`;

    const { tax: taxAPI } = await this.http.post<{ tax: TaxDTO }>(url, tax).toPromise();

    return new Tax().deserialize(taxAPI);
  }

  async updateStatementTax(statement: Statement, tax: Tax): Promise<Tax> {
    const url = `${this.apiUrl}/statement/${statement.id}/tax/${tax.id}`;

    const { tax: taxAPI } = await this.http.put<{ tax: TaxDTO }>(url, tax).toPromise();

    return new Tax().deserialize(taxAPI);
  }

  async deleteStatementTax(statementId: string, taxId: string): Promise<void> {
    const url = `${this.apiUrl}/statement/${statementId}/tax/${taxId}`;

    await this.http.delete(url).toPromise();
  }
}
