/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { UserDTO, User } from './user';

export interface RightHolderDTO {
  id?: string;
  name: string;
  ipi?: number;
  unisonId?: string;
  user?: UserDTO;
  userId?: string;
  // collectionShares: CollectionShare[];
  // ownershipShares: OwnershipShare[];
  affiliations?: string;
  updatedAt?: string;
  createdAt?: string;
  performanceSocietyId?: string;
  mechanicalSocietyId?: string;
  synchronizationSocietyId?: string;
}

export class RightHolder implements Serializable<RightHolderDTO> {
  id?: string;
  name: string;
  ipi?: number;
  unisonId?: string;
  user?: User;
  userId?: string;
  affiliations: string;
  // collectionShares: CollectionShare[];
  // ownershipShares: OwnershipShare[];
  updatedAt?: string;
  createdAt?: string;

  constructor() {
    this.id = null;
    this.name = null;
    this.ipi = null;
    this.unisonId = null;
    this.user = null;
    this.userId = null;
    this.affiliations = null;
  }

  deserialize(input: RightHolderDTO): RightHolder {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.ipi = input.ipi || null;
    this.unisonId = input.unisonId || '';
    this.user = input.user ? new User().deserialize(input.user) : null;
    this.userId = input.userId || '';
    this.affiliations = input.affiliations || '';
    // this.collectionShares = input.CollectionScollectionShares;
    // this.ownershipShares =input.OwnershipSownershipShares;
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<RightHolderDTO>): Array<RightHolder> {
    const rightHolders: Array<RightHolder> = inputArray.map((input) =>
      new RightHolder().deserialize(input),
    );

    return rightHolders;
  }

  clone(): RightHolder {
    const clonedInstance: RightHolder = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
