import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'environments/environment';
import {
  Work,
  AlternateTitle,
  OwnershipShare,
  CollectionShare,
  WorkDTO,
  SOURCES,
  CueSheet,
  CueSheetDTO,
  TerritoryDTO,
  Trend,
  TrendDTO,
  ShareDTO,
  Share,
} from 'app/shared/models';

import {
  WorksResponseAPII,
  Role,
  Sequence,
  WorkFiltersI,
  InfoResponseAPI,
  WorkRoyaltiesResponse,
  WorkAPIResponse,
  AlternateTitlesResponse,
  AlternateTitleAPIResponse,
  SequencesAPIResponse,
  RolesAPIResponse,
  OwnershipShareUpdateAPIResponse,
  CollectionShareUpdatedAPIResponse,
  OwnershipSharesAPIResponse,
  OwnershipShareAPIResponse,
  ValidateResponseI,
  WorkRoyaltiesPlatformI,
  WorkRoyaltiesCountryI,
  WorkTrendsAPI,
  WorksSearchByTextResponseI,
  WorkSearchByTextResponseAPII,
  TopWorksBySourceI,
  PlatformRoyaltiesApiI,
} from 'app/shared/interfaces';

import {
  WORKS_ROYALTIES_MOCK,
  WORKS_USAGES_BROADCAST_MOCK,
  WORKS_ALL_MOCK,
  ALTERNATE_TITLES_MOCK,
  OWNERSHIPSHARES_MOCK,
  OWNERSHIP_ROLES,
  ALL_ROLES,
  SEQUENCES_MOCK,
  WORKS_ALL_MOCK_2,
  WORKS_USAGES_RADIO_MOCK,
  WORKS_USAGES_TV_MOCK,
  CUE_SHEETS_WORK_MOCK,
} from 'app/shared/mocks';
import { TrendPlayI, TypePlays } from './usage.service';

// import { tap, catchError } from 'rxjs/operators';

// const SOURCES = ['radio', 'tv'];

interface WorkCollectionShares {
  publishers: Array<Share>;
  writers: Array<Share>;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface WorkUsagesI {
  composition: WorkDTO;
  owners: string;
  plays: string;
}
interface WorkTerritoriesRoyaltiesI {
  royalties: number;
  territory: TerritoryDTO;
}

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  private readonly apiUrl: string = environment.apiUrl;

  private readonly workSubject = new BehaviorSubject<Work>(null);
  readonly currentWork$ = this.workSubject.asObservable();

  get currentWork(): Work {
    return this.workSubject.getValue();
  }

  set currentWork(work: Work) {
    this.workSubject.next(work);
  }

  constructor(private http: HttpClient) {}

  async getWork(workId: string): Promise<Work> {
    const url = `${this.apiUrl}/composition/${workId}/`;
    let workFetched: Work = null;

    if (environment.useMocks) {
      const work: WorkDTO = WORKS_ALL_MOCK.find((w: Work) => w.id === workId);
      workFetched = new Work().deserialize(work);
    } else {
      const response: WorkAPIResponse = await this.http
        .get<WorkAPIResponse>(url, httpOptions)
        .toPromise();
      const workAPI = response.composition;
      workFetched = new Work().deserialize(workAPI);
    }
    return workFetched;
  }

  async getWorks(
    offset: number,
    limit: number,
    filters?: WorkFiltersI,
  ): Promise<WorksResponseAPII> {
    const url = `${this.apiUrl}/composition`;

    if (environment.useMocks) {
      // Apply filters to mockups
      const allWorks = new Work()
        .deserializeArray(WORKS_ALL_MOCK)
        .filter((work: Work) => {
          if (!filters.text) {
            return true;
          }

          return work.title.includes(filters.text) || work.unisonId.includes(filters.text);
        })
        .filter((work: Work) => (filters.status ? work.status === filters.status : true));

      // Apply pagination on filtered works
      const worksFetched = allWorks.slice(offset, offset + limit);

      const infoResponse = {
        count: allWorks.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };

      return {
        works: new Work().deserializeArray(worksFetched),
        filters,
        info: infoResponse,
      };
    }

    const { compositions: worksAPI, info } = await this.http
      .get<{ compositions: WorkDTO[]; info: InfoResponseAPI }>(url, {
        params: {
          offset: offset ? offset.toString() : '',
          limit: limit ? limit.toString() : '',
          ...filters,
        },
      })
      .toPromise();

    return {
      works: new Work().deserializeArray(worksAPI),
      filters,
      info,
    };
  }

  async createWork(work: Work): Promise<Work> {
    const url = `${this.apiUrl}/composition/`;
    const { composition: workApi } = await this.http
      .post<{ composition: WorkDTO }>(url, work, httpOptions)
      .toPromise();

    return new Work().deserialize(workApi);
  }

  async updateWork(workId: string, work: Work): Promise<Work> {
    let workUpdated: Work = null;
    if (environment.useMocks) {
      workUpdated = work;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/`;
      const response: WorkAPIResponse = await this.http
        .put<WorkAPIResponse>(url, work, httpOptions)
        .toPromise();
      const workAPI = response.composition;
      workUpdated = new Work().deserialize(workAPI);
    }
    return workUpdated;
  }

  async deleteWork(workId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/`;

    await this.http.delete(url).toPromise();
  }

  async getTopEarningWorksFromRightHolder(
    offset: number,
    limit: number,
    timeFilter: string = null,
    typeFilter: string = null,
  ): Promise<Array<Work>> {
    let worksFetched: Array<Work> = null;
    let filters = `?offset=${offset}&limit=${limit}`;
    if (timeFilter) {
      filters += `&period=${timeFilter}`;
    }
    if (typeFilter) {
      filters += `&type=${typeFilter}`;
    }

    if (environment.useMocks) {
      worksFetched = new Work().deserializeArray(WORKS_ROYALTIES_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/royalties/${filters}`;
      const response: WorkRoyaltiesResponse = await this.http
        .get<WorkRoyaltiesResponse>(url, httpOptions)
        .toPromise();

      const { works } = response;
      worksFetched = new Work().deserializeArray(works);
    }
    return worksFetched;
  }

  async getWorksUsageByBroadcast(
    offset: number,
    limit: number,
    timeFilter: string = null,
    typeFilter: string = null,
  ): Promise<Array<Work>> {
    let worksUsagesFetched: Array<Work> = [];
    let filters = `?offset=${offset}&limit=${limit}`;
    if (timeFilter) {
      filters += `&period=${timeFilter}`;
    }
    if (typeFilter) {
      filters += `&type=${typeFilter}`;
    }

    if (environment.useMocks) {
      worksUsagesFetched = new Work().deserializeArray(WORKS_USAGES_BROADCAST_MOCK);
    } else {
      // TODO: Implement API Call
      const url = `${this.apiUrl}/ticket/me/${filters}`;
      await this.http.get<WorkRoyaltiesResponse>(url, httpOptions).toPromise();
    }

    return worksUsagesFetched;
  }

  async getWorksMostListenedByPlatform(
    offset: number,
    limit: number,
    type: string,
    period: string,
    platformId: string = '',
    platformName: string = '',
  ): Promise<{ workUsagesApi: WorkUsagesI[]; platformName: string }> {
    // let worksTopByOnePlatformFetched: Array<Work>;
    // let worksTopByPlatformFetched: Array<TopWorksByPlatformI>;
    // if (environment.useMocks) {
    //   worksTopByPlatformFetched = platforms.map((platform: string) => {
    //     if (platform === PLATFORMS[0]) {
    //       worksTopByOnePlatformFetched = new Work().deserializeArray(WORKS_USAGES_SPOTIFY_MOCK);
    //     } else if (platform === PLATFORMS[1]) {
    //       worksTopByOnePlatformFetched = new Work().deserializeArray(WORKS_USAGES_APPLE_MUSIC_MOCK);
    //     } else if (platform === PLATFORMS[2]) {
    //       worksTopByOnePlatformFetched = new Work().deserializeArray(WORKS_USAGES_DEEZER_MOCK);
    //     } else if (platform === PLATFORMS[3]) {
    //       worksTopByOnePlatformFetched = new Work().deserializeArray(WORKS_USAGES_BEATPORT_MOCK);
    //     } else {
    //       worksTopByOnePlatformFetched = [];
    //     }
    //     return {
    //       platform,
    //       works: worksTopByOnePlatformFetched,
    //     };
    //   });
    //   // compositionsTopByPlatformFetched = new CompositionBroadcast().deserializeArray(WORKS_USAGES_BROADCAST_MOCK);
    // } else {
    const url = `${this.apiUrl}/composition/plays`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      type: type ? type.toString() : '',
      period: period ? period.toString() : '',
      platformId: platformId ? platformId.toString() : '',
    };
    const { works: workUsagesApi } = await this.http
      .get<{ works: WorkUsagesI[]; info: InfoResponseAPI }>(url, { params })
      .toPromise();
    // }

    return { workUsagesApi, platformName };
  }

  async getWorksMostListenedBySource(
    offset: number,
    limit: number,
    sources: Array<string>,
  ): Promise<Array<TopWorksBySourceI>> {
    let worksTopByOneSourceFetched: Array<Work>;
    let worksTopBySourceFetched: Array<TopWorksBySourceI>;
    if (environment.useMocks) {
      worksTopBySourceFetched = sources.map((source: string) => {
        if (source === SOURCES[0]) {
          worksTopByOneSourceFetched = new Work().deserializeArray(WORKS_USAGES_RADIO_MOCK);
        } else if (source === SOURCES[1]) {
          worksTopByOneSourceFetched = new Work().deserializeArray(WORKS_USAGES_TV_MOCK);
        } else {
          worksTopByOneSourceFetched = [];
        }
        return {
          source,
          works: worksTopByOneSourceFetched,
        };
      });
    } else {
      // TODO: Implement API Call
      const url = `${this.apiUrl}/ticket/me/${sources[1]}`;
      await this.http.get<WorkRoyaltiesResponse>(url, httpOptions).toPromise();
    }

    return worksTopBySourceFetched;
  }

  async searchWorksByText(
    offset: number,
    limit: number,
    text: string,
  ): Promise<WorksSearchByTextResponseI> {
    let worksFetched: Work[];
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      // Apply filters to mockups
      const allWorks = new Work()
        .deserializeArray(WORKS_ALL_MOCK_2.compositions)
        .filter((work: Work) => {
          if (!text) {
            return true;
          }

          return work.title.includes(text) || work.unisonId.includes(text);
        });

      worksFetched = allWorks;

      infoResponse = {
        count: allWorks.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };
    } else {
      let filters = `?offset=${offset}&limit=${limit}`;

      if (text) {
        filters += `&text=${text}`;
      }

      const url = `${this.apiUrl}/composition/${filters}`;
      const response: WorkSearchByTextResponseAPII = await this.http
        .get<WorkSearchByTextResponseAPII>(url, httpOptions)
        .toPromise();
      worksFetched = new Work().deserializeArray(response.compositions);

      infoResponse = {
        count: response.info.count,
        offset: response.info.offset,
        limit: response.info.limit,
      };
    }

    return {
      works: worksFetched,
      info: infoResponse,
    };
  }

  async getAlternateTitlesFromWork(workId: string): Promise<Array<AlternateTitle>> {
    let alternateTitlesFetched: AlternateTitle[] = [];

    if (environment.useMocks) {
      alternateTitlesFetched = new AlternateTitle().deserializeArray(ALTERNATE_TITLES_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/alternate-title/`;
      const response: AlternateTitlesResponse = await this.http
        .get<AlternateTitlesResponse>(url, httpOptions)
        .toPromise();
      const { alternateTitles } = response;
      alternateTitlesFetched = new AlternateTitle().deserializeArray(alternateTitles);
    }
    return alternateTitlesFetched;
  }

  async addAlternateTitleToWork(
    workId: string,
    alternateTitleToAdd: AlternateTitle,
  ): Promise<AlternateTitle> {
    let alternateTitleAdded: AlternateTitle = null;

    if (environment.useMocks) {
      alternateTitleAdded = new AlternateTitle().deserialize(alternateTitleToAdd);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/alternate-title/`;
      try {
        const response: AlternateTitleAPIResponse = await this.http
          .post<AlternateTitleAPIResponse>(url, alternateTitleToAdd, httpOptions)
          .toPromise();
        const { alternateTitle } = response;
        alternateTitleAdded = new AlternateTitle().deserialize(alternateTitle);
      } catch (error) {
        // console.log(error);
      }
    }
    return alternateTitleAdded;
  }

  async deleteAlternateTitleFromWork(workId: string, alternateTitleId: string): Promise<void> {
    if (environment.useMocks) {
      // console.log('DELETED ALTERNATE TITLE');
    } else {
      const url = `${this.apiUrl}/composition/${workId}/alternate-title/${alternateTitleId}/`;
      await this.http.delete(url).toPromise();
    }
  }

  async editAlternateTitle(workId: string, alternateTitle: AlternateTitle): Promise<void> {
    if (environment.useMocks) {
      // console.log('DELETED ALTERNATE TITLE');
    } else {
      const url = `${this.apiUrl}/composition/${workId}/alternate-title/${alternateTitle.id}/`;
      await this.http.put(url, alternateTitle, httpOptions).toPromise();
    }
  }

  async createOwnershipShare(
    workId: string,
    ownershipShare: OwnershipShare,
  ): Promise<OwnershipShare> {
    let ownershipShareCreated: OwnershipShare = null;

    if (environment.useMocks) {
      ownershipShareCreated = ownershipShare;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/`;
      const response: OwnershipShareAPIResponse = await this.http
        .post<OwnershipShareAPIResponse>(url, ownershipShare, httpOptions)
        .toPromise();
      ownershipShareCreated = new OwnershipShare().deserialize(response.composition); // API returns composition as OwnershipShare
    }
    return ownershipShareCreated;
  }

  async editOwnershipShare(
    workId: string,
    ownershipShare: OwnershipShare,
  ): Promise<OwnershipShare> {
    let ownershipShareEdited: OwnershipShare = null;

    if (environment.useMocks) {
      ownershipShareEdited = ownershipShare;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/${ownershipShare.id}`;
      const response: OwnershipShareUpdateAPIResponse = await this.http
        .put<OwnershipShareUpdateAPIResponse>(url, ownershipShare)
        .toPromise();
      ownershipShareEdited = new OwnershipShare().deserialize(response.ownershipShare);
    }
    return ownershipShareEdited;
  }

  async deleteOwnershipShareFromWork(workId: string, ownershipShareId: string): Promise<void> {
    if (environment.useMocks) {
      // console.log('DELETED OWNERSHIPSHARE');
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/${ownershipShareId}`;
      await this.http.delete(url, httpOptions).toPromise();
    }
  }

  async getOwnershipSharesFromWork(workId: string): Promise<OwnershipShare[]> {
    let ownershipSharesFetched: OwnershipShare[] = [];

    if (environment.useMocks) {
      ownershipSharesFetched = new OwnershipShare().deserializeArray(OWNERSHIPSHARES_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/`;
      const response: OwnershipSharesAPIResponse = await this.http
        .get<OwnershipSharesAPIResponse>(url, httpOptions)
        .toPromise();
      const { ownershipShares } = response;
      ownershipSharesFetched = new OwnershipShare().deserializeArray(ownershipShares);
    }
    return ownershipSharesFetched;
  }

  async getOwnershipSharesRoles(): Promise<Role[]> {
    let ownerShipRolesFetched: Role[] = [];
    if (environment.useMocks) {
      ownerShipRolesFetched = OWNERSHIP_ROLES;
    } else {
      const url = `${this.apiUrl}/composition/ownership-share/roles`;
      const response: RolesAPIResponse = await this.http.get<RolesAPIResponse>(url).toPromise();
      ownerShipRolesFetched = response.roles;
    }
    return ownerShipRolesFetched;
  }

  async getCollectionSharesFromWork(workId: string): Promise<WorkCollectionShares> {
    // let collectionSharesFetched: WorkCollectionShares = null;

    // if (environment.useMocks) {
    //   collectionSharesFetched = new CollectionShare().deserializeArray(OWNERSHIPSHARES_MOCK);
    // }

    const url = `${this.apiUrl}/composition/${workId}/collection-share/`;
    const { tree } = await this.http
      .get<{ tree: { publishers: Array<ShareDTO>; writers: Array<ShareDTO> } }>(url, httpOptions)
      .toPromise();

    return {
      publishers: new Share().deserializeArray(tree.publishers),
      writers: new Share().deserializeArray(tree.writers),
    };
  }

  async getCollectionSharesRoles(parentRole: string): Promise<Role[]> {
    let collectionSharesRolesFetched: Role[] = []; // TODO Unify ROLE interface
    const term = {
      parent: parentRole !== '' ? parentRole : undefined,
    };

    if (environment.useMocks) {
      if (!term.parent || parentRole === undefined) {
        collectionSharesRolesFetched = ALL_ROLES;
      }
      if (['E'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [
          { code: 'AM', name: 'Administrator' },
          { code: 'SE', name: 'Sub Publisher' },
          // { code: 'AQ', name: 'Acquirer' },
          { code: 'ES', name: 'Substituted Publisher' },
        ];
      } else if (['CA', 'C', 'A', 'AD', 'AR', 'SR', 'SA', 'TR'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [{ code: 'PWR', name: 'Publisher for Writer' }];
      } else if (['AM', 'AQ'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [
          { code: 'SE', name: 'Sub Publisher' },
          { code: 'ES', name: 'Substituted Publisher' },
        ];
      } else if (['SE'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [{ code: 'ES', name: 'Substituted Publisher' }];
      } else if (['PA'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [
          { code: 'AM', name: 'Administrator' },
          { code: 'SE', name: 'Sub Publisher' },
          // { code: 'AQ', name: 'Acquirer' },
          { code: 'ES', name: 'Substituted Publisher' },
          { code: 'PWR', name: 'Publisher for Writer' },
        ];
      }
      // collectionSharesRolesFetched = ALL_ROLES;
      // collectionSharesRolesFetched = COLLECTIONSHARE_ROLES_ROOT;
    } else {
      const url = `${this.apiUrl}/composition/collection-share/roles`;
      if (term.parent) {
        const params = new HttpParams().append('parent', term.parent);
        const response: RolesAPIResponse = await this.http
          .get<RolesAPIResponse>(url, { params })
          .toPromise();
        collectionSharesRolesFetched = response.roles;
      } else {
        const response: RolesAPIResponse = await this.http.get<RolesAPIResponse>(url).toPromise();
        collectionSharesRolesFetched = response.roles;
      }
    }

    return collectionSharesRolesFetched;
  }

  async getSequencesFromWork(id: string): Promise<Sequence[]> {
    let sequencesFetched: Sequence[] = [];
    if (environment.useMocks) {
      sequencesFetched = SEQUENCES_MOCK;
    } else {
      const url = `${this.apiUrl}/composition/${id}/sequence/`;
      const response: SequencesAPIResponse = await this.http
        .get<SequencesAPIResponse>(url)
        .toPromise();
      sequencesFetched = response.sequences;
    }
    return sequencesFetched;
  }

  async deleteCollectionShareFromWork(id: string, collectionShareId: string): Promise<void> {
    if (environment.useMocks) {
      // console.log('DELETED COLLECTIONSHARE FROM WORK');
    } else {
      const url = `${this.apiUrl}/composition/${id}/collection-share/${collectionShareId}`;
      await this.http.delete(url).toPromise();
    }
  }

  async validateCollectionShare(id: string, collectionShare: CollectionShare): Promise<boolean> {
    let validated: boolean;
    if (environment.useMocks) {
      validated = true;
    } else {
      const url = `${this.apiUrl}/composition/${id}/collection-share/validate`;
      const response: ValidateResponseI = await this.http
        .post<ValidateResponseI>(url, collectionShare, httpOptions)
        .toPromise();
      validated = response.validated;
    }
    return validated;
  }

  async addCollectionShareToWork(id: string, collectionShare: CollectionShare): Promise<void> {
    if (environment.useMocks) {
      // console.log('Added CollectionShare To Work');
    } else {
      const url = `${this.apiUrl}/composition/${id}/collection-share/`;
      await this.http.post(url, collectionShare, httpOptions).toPromise();
      //  .pipe(
      // tap(() => this.log(`collection share added to composition with id = ${id}`)),
      // catchError(this.handleError),
      // );
    }
  }

  async editCollectionShare(
    workId: string,
    collectionShare: CollectionShare,
  ): Promise<CollectionShare> {
    let collectionShareUpdated: CollectionShare;
    if (environment.useMocks) {
      // console.log('Edit CollectionShare');

      collectionShareUpdated = collectionShare;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/collection-share/${collectionShare.id}`;
      const response: CollectionShareUpdatedAPIResponse = await this.http
        .put<CollectionShareUpdatedAPIResponse>(url, collectionShare)
        .toPromise();
      collectionShareUpdated = new CollectionShare().deserialize(response.collectionShare);
    }
    return collectionShareUpdated;
  }

  async getWorkUsageBySource(
    id: string,
    sourceTypes: string[],
    timeFilter: string = null,
  ): Promise<TypePlays[]> {
    // let filter = '';
    // const workUsages: WorkUsageI[] = [];
    // if (environment.useMocks) {
    //   if (timeFilter === TIMES[0]) {
    //     workUsages = WORK_USAGES_Y;
    //   } else if (timeFilter === TIMES[1]) {
    //     workUsages = WORK_USAGES_QT;
    //   } else if (timeFilter === TIMES[2]) {
    //     workUsages = WORK_USAGES_M;
    //   }
    // } else {
    //   if (timeFilter) {
    //     filter += `&period=${timeFilter}`;
    //   }

    const params = {
      types: sourceTypes ? sourceTypes.join() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/plays`;
    const { plays } = await this.http
      .get<{ plays: TypePlays[] }>(url, { params })
      .toPromise();

    // }
    return plays;
  }

  async getWorkRoyaltyTrendsBySource(
    id: string,
    sourceTypes: string[],
    timeFilter: string = null,
  ): Promise<Trend[]> {
    // let filter = '';
    const workTrends: WorkTrendsAPI[] = [];
    // if (environment.useMocks) {
    //   if (timeFilter === TIMES[0]) {
    //     workTrends = WORK_TRENDS_Y;
    //   } else if (timeFilter === TIMES[1]) {
    //     workTrends = WORK_TRENDS_QT;
    //   } else if (timeFilter === TIMES[2]) {
    //     workTrends = WORK_TRENDS_M;
    //   }
    // } else {
    //   if (timeFilter) {
    //     filter += `&period=${timeFilter}`;
    //   }

    const params = {
      types: sourceTypes ? sourceTypes.join() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/earnings/trend`;
    const { trend: trendsApi } = await this.http
      .get<{ trend: TrendDTO[] }>(url, { params })
      .toPromise();

    // }
    return new Trend().deserializeArray(trendsApi);
  }

  async getWorkPlaysTrendsBySource(
    id: string,
    sourceTypes: string[],
    timeFilter: string = null,
  ): Promise<TrendPlayI[]> {
    const params = {
      types: sourceTypes ? sourceTypes.join() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/plays/trend`;
    const { trend: trendsPlaysApi } = await this.http
      .get<{ trend: TrendPlayI[] }>(url, { params })
      .toPromise();

    return trendsPlaysApi;
  }

  async getWorkEarningsByPlatform(
    id: string,
    offset: number,
    limit: number,
    timeFilter: string = null,
    sourceType: string = 'digital',
  ): Promise<{ platforms: WorkRoyaltiesPlatformI[]; info: InfoResponseAPI }> {
    // let filter = '';
    // let workEarnings: WorkRoyaltiesPlatformI[] = [];
    // if (environment.useMocks) {
    //   if (timeFilter === TIMES[0]) {
    //     workEarnings = WORK_EARNINGS_Y;
    //   } else if (timeFilter === TIMES[1]) {
    //     workEarnings = WORK_EARNINGS_QT;
    //   } else if (timeFilter === TIMES[2]) {
    //     workEarnings = WORK_EARNINGS_M;
    //   }
    // } else {
    //   if (timeFilter) {
    //     filter += `&period=${timeFilter}`;
    //   }
    const url = `${this.apiUrl}/composition/${id}/royalties/platform/`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      period: timeFilter ? timeFilter.toString() : '',
      type: sourceType ? sourceType.toString() : '',
    };

    const { platforms: platformsApi, info } = await this.http
      .get<{ info: InfoResponseAPI; platforms: PlatformRoyaltiesApiI[] }>(url, { params })
      .toPromise();

    const platforms = platformsApi.map(
      (platformApi: PlatformRoyaltiesApiI): WorkRoyaltiesPlatformI => {
        return {
          source: platformApi.platform.name,
          royalties: platformApi.royalties,
        };
      },
    );

    // }
    return { platforms, info };
  }

  async getWorkEarningsByCountry(
    id: string,
    offset: number,
    limit: number,
    timeFilter: string = null,
  ): Promise<{ countryRoyalties: WorkRoyaltiesCountryI[]; info: InfoResponseAPI }> {
    // let filter = '';
    let countryRoyalties: WorkRoyaltiesCountryI[] = [];
    // if (environment.useMocks) {
    //   if (timeFilter === TIMES[0]) {
    //     workEarnings = WORK_EARNINGS_COUNTRY_Y;
    //   } else if (timeFilter === TIMES[1]) {
    //     workEarnings = WORK_EARNINGS_COUNTRY_QT;
    //   } else if (timeFilter === TIMES[2]) {
    //     workEarnings = WORK_EARNINGS_COUNTRY_M;
    //   }
    // } else {
    //   if (timeFilter) {
    //     filter += `&period=${timeFilter}`;
    //   }
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/royalties/territory/`;
    const { info, territories: territoriesApi } = await this.http
      .get<{ info: InfoResponseAPI; territories: WorkTerritoriesRoyaltiesI[] }>(url, httpOptions)
      .toPromise();

    countryRoyalties = territoriesApi.map(
      (wTR: WorkTerritoriesRoyaltiesI): WorkRoyaltiesCountryI => {
        return {
          country: wTR.territory.name,
          royalties: wTR.royalties,
        };
      },
    );
    // }
    return { countryRoyalties, info };
  }

  async getCueSheetsFromWork(workId: string): Promise<Array<CueSheet>> {
    let cueSheetsFetched: Array<CueSheet> = [];
    if (environment.useMocks) {
      cueSheetsFetched = new CueSheet().deserializeArray(CUE_SHEETS_WORK_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/cue-sheets/`; // TODO: Endpoint
      const response = await this.http
        .get<{ cueSheets: Array<CueSheetDTO> }>(url, httpOptions)
        .toPromise();
      cueSheetsFetched = new CueSheet().deserializeArray(response.cueSheets);
    }
    return cueSheetsFetched;
  }

  async deleteCueSheet(cueSheetId: string, workId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/${cueSheetId}/`;
    await this.http.delete(url).toPromise();
  }

  downloadWorksAsCWR(): Observable<any> {
    const url = `${this.apiUrl}/composition/download-cwr`;
    return this.http.get(url, {
      observe: 'events', // 'body'
      params: { cShares: 'true' },
      responseType: 'blob',
      reportProgress: true,
    });
  }
}
