import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { Partner, PartnerDTO } from 'app/shared/models';

export interface PartnersResponse {
  partners: Partner[];
  info: InfoResponseAPI;
}

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getPartners(offset: number, limit: number, name: string = ''): Promise<PartnersResponse> {
    let filters = `?offset=${offset}&limit=${limit}`;

    if (name) {
      filters += `&name=${name}`;
    }

    const url = `${this.apiUrl}/partner/${filters}`;

    const { partners: partnersAPI, info } = await this.http
      .get<{ partners: PartnerDTO[]; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      partners: new Partner().deserializeArray(partnersAPI),
      info,
    };
  }

  async getPartner(partnerId: string): Promise<Partner> {
    const url = `${this.apiUrl}/partner/${partnerId}/`;

    const { partner: partnerAPI } = await this.http.get<{ partner: PartnerDTO }>(url).toPromise();

    return new Partner().deserialize(partnerAPI);
  }

  async createPartner(partner: Partner): Promise<Partner> {
    const url = `${this.apiUrl}/partner/`;

    const { partner: partnerAPI } = await this.http
      .post<{ partner: PartnerDTO }>(url, partner)
      .toPromise();

    return new Partner().deserialize(partnerAPI);
  }

  async updatePartner(partner: Partner): Promise<Partner> {
    const url = `${this.apiUrl}/partner/${partner.id}/`;

    const { partner: partnerAPI } = await this.http
      .put<{ partner: PartnerDTO }>(url, partner)
      .toPromise();

    return new Partner().deserialize(partnerAPI);
  }

  async deletePartner(partnerId: string): Promise<void> {
    const url = `${this.apiUrl}/partner/${partnerId}/`;

    await this.http.delete(url).toPromise();
  }

  async sendEmailToPartner(partnerId: string): Promise<void> {
    const url = `${this.apiUrl}/partner/${partnerId}/send-mail/`;

    await this.http.post(url, {}).toPromise();
  }
}
