import { Serializable } from 'app/core/interfaces';

interface PartnerDownload {
  acceptedConditions: boolean;
  createdAt: string;
  datetime: string;
  fileType: string;
  id: string;
  partnerId: string;
  updatedAt: string;
}

export interface PartnerDTO {
  id?: string;
  name?: string;
  email?: string;
  pin?: string;
  expiry?: string;
  updatedAt?: string;
  createdAt?: string;
  downloads?: Array<PartnerDownload>;
  allowCWRDownload: boolean;
  includeCShares: boolean;
  url: string;
}

export class Partner implements Serializable<Partner> {
  id: string;
  name: string;
  email: string;
  pin: string;
  expiry: string;
  updatedAt: string;
  createdAt: string;
  downloads: Array<any>;
  allowCWRDownload: boolean;
  includeCShares: boolean;
  url: string;

  deserialize(input: PartnerDTO): Partner {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.email = input.email || '';
    this.pin = input.pin || '';
    this.expiry = input.expiry || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.downloads = input.downloads || [];
    this.allowCWRDownload = input.allowCWRDownload || false;
    this.includeCShares = input.includeCShares || false;
    this.url = input.url || '';

    return this;
  }

  deserializeArray(inputArray: Array<PartnerDTO>): Array<Partner> {
    const partners: Partner[] = inputArray.map((input) => new Partner().deserialize(input));

    return partners;
  }

  clone(): Partner {
    const clonedInstance: Partner = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
