import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { Platform, PlatformDTO } from '../models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface PlatformPlaysI {
  platform: PlatformDTO;
  plays: string;
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getPlatformsByPlays(
    offset: number,
    limit: number,
    period: string = '',
    type: string = '',
  ): Promise<PlatformPlaysI[]> {
    let filters = `?offset=${offset}&limit=${limit}`;

    if (period !== '') {
      filters += `&period=${period}`;
    }
    if (type !== '') {
      filters += `&type=${type}`;
    }

    const url = `${this.apiUrl}/platform/plays/${filters}`;

    const { platforms: platformsApi } = await this.http
      .get<{ platforms: PlatformPlaysI[]; info: InfoResponseAPI }>(url, httpOptions)
      .toPromise();

    return platformsApi;
  }
}
