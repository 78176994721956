import { WorkDTO } from 'app/shared/models';

export const WORKS_USAGES_RADIO_MOCK: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
  },
];

export const WORKS_USAGES_TV_MOCK: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 1500000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 1275000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 280000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 195000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
  },
];

// export const WORKS_USAGES_DEEZER_MOCK: Array<WorkListenedAPI> = [
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'Altered Choices',
//     usages: 110000,
//     rightHolders: [
//       {
//         name: 'Rosa Flynn',
//       },
//     ],
//   },
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'Los Silbidos',
//     usages: 75000,
//     rightHolders: [
//       {
//         name: 'Raw talent publisher',
//       },
//       {
//         name: 'Norman cleric',
//       },
//     ],
//   },
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'Remember my step',
//     usages: 8000,
//     rightHolders: [
//       {
//         name: 'LOUD',
//       },
//       {
//         name: 'Derek Andrade',
//       },
//     ],
//   },
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'I\'m bad',
//     usages: 5000,
//     rightHolders: [
//       {
//         name: 'Manuel Cebollo',
//       },
//       {
//         name: 'Norman cleric',
//       },
//     ],
//   },
// ];

// export const WORKS_USAGES_BEATPORT_MOCK: Array<WorkListenedAPI> = [
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'Altered Choices',
//     usages: 1100,
//     rightHolders: [
//       {
//         name: 'Rosa Flynn',
//       },
//     ],
//   },
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'Los Silbidos',
//     usages: 750,
//     rightHolders: [
//       {
//         name: 'Raw talent publisher',
//       },
//       {
//         name: 'Norman cleric',
//       },
//     ],
//   },
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'Remember my step',
//     usages: 80,
//     rightHolders: [
//       {
//         name: 'LOUD',
//       },
//       {
//         name: 'Derek Andrade',
//       },
//     ],
//   },
//   {
//     id: '34df-dfs3-4598-ewrt',
//     unisonId: '34df-dfs3-4598-ewrt',
//     title: 'I\'m bad',
//     usages: 50,
//     rightHolders: [
//       {
//         name: 'Manuel Cebollo',
//       },
//       {
//         name: 'Norman cleric',
//       },
//     ],
//   },
// ];
