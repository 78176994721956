/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';

import {
  CollectionShareLiquidation,
  CollectionShareLiquidationDTO,
} from './collectionShareLiquidation';

import { StatementDTO, Statement } from './statement';
import { UsageTypes } from './usage';
import { User, UserDTO } from './user';

export interface LiquidationDTO {
  id?: string;
  month?: number;
  year?: number;
  amount?: number;
  type: UsageTypes;
  user?: UserDTO;
  userId?: string;
  dateFrom: string;
  dateTo: string;
  collectionShareLiquidations?: CollectionShareLiquidationDTO[];
  updatedAt?: string;
  createdAt?: string;
  unisonStatementId?: string;
  unisonStatement: StatementDTO;
  clientStatementId?: string;
  clientStatement: StatementDTO;
  commission: number;
}

export class Liquidation implements Serializable<Liquidation> {
  id?: string;
  month?: number;
  year?: number;
  amount?: number;
  type: UsageTypes;
  user?: User;
  userId?: string;
  dateFrom: string;
  dateTo: string;
  collectionShareLiquidations?: CollectionShareLiquidation[];
  unisonStatementId: string;
  clientStatementId?: string;
  unisonStatement: Statement;
  clientStatement: Statement;
  updatedAt?: string;
  createdAt?: string;
  commission: number;

  deserialize(input: LiquidationDTO): Liquidation {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.month = input.month || 12;
    this.year = input.year || 1960;
    this.amount = input.amount || 0;
    this.commission = input.commission || 10;
    this.userId = input.userId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.dateFrom = input.dateFrom ? this.formatDate(input.dateFrom) : '';
    this.dateTo = input.dateTo ? this.formatDate(input.dateTo) : '';
    this.unisonStatementId = input.unisonStatementId || '';
    this.clientStatementId = input.clientStatementId || '';
    this.type = input.type || UsageTypes.DIGITAL;

    this.user = new User().deserialize(input.user);

    if (input.collectionShareLiquidations) {
      this.collectionShareLiquidations = new CollectionShareLiquidation().deserializeArray(
        input.collectionShareLiquidations,
      );
    }

    if (input.unisonStatement) {
      this.unisonStatement = new Statement().deserialize(input.unisonStatement);
    }
    if (input.clientStatement) {
      this.clientStatement = new Statement().deserialize(input.clientStatement);
    }

    return this;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }

  deserializeArray(inputArray: Array<LiquidationDTO>): Array<Liquidation> {
    const liquidations: Array<Liquidation> = inputArray.map((input) =>
      new Liquidation().deserialize(input),
    );

    return liquidations;
  }

  clone(): Liquidation {
    const clonedInstance: Liquidation = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
