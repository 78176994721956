/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Work, WorkDTO } from './work';
import { Message, MessageAPI } from './message';
import { User, UserDTO } from './user';
import { RightHolder, RightHolderDTO } from './rightHolder';

export enum TicketStatus {
  CLOSED = 'closed',
  OPEN = 'open',
}
export interface TicketDTO {
  id: string;
  unisonId: string;
  status: string;
  topic: string;
  description: string;
  updatedAt: string;
  createdAt: string;
  compositionId: string;
  composition?: WorkDTO;
  work?: WorkDTO;
  workId?: string;
  messages: MessageAPI[];
  user?: UserDTO;
  rightHolders?: RightHolderDTO[];
  userId?: string;
}

export class Ticket implements Serializable<Ticket> {
  id: string;
  unisonId: string;
  status: TicketStatus;
  topic: string;
  description: string;
  workId: string;
  updatedAt: string;
  createdAt: string;
  // TODO: Change composition when API Changes
  compositionId?: string;
  work?: Work;
  messages: Message[];
  user: User;
  userId: string;
  rightHolders: RightHolder[];

  deserialize(input: TicketDTO): Ticket {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.status = (input.status as TicketStatus) || TicketStatus.OPEN;
    this.topic = input.topic || '';
    this.description = input.description || '';
    this.workId = input.workId || '';
    this.compositionId = input.compositionId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.work = new Work().deserialize(input.composition) || null;
    this.messages = input.messages ? new Message().deserializeArray(input.messages) : [];
    this.user = input.user ? new User().deserialize(input.user) : null;
    this.userId = input.userId || '';
    this.rightHolders = input.rightHolders
      ? new RightHolder().deserializeArray(input.rightHolders)
      : [];

    return this;
  }

  deserializeArray(inputArray: Array<TicketDTO>): Array<Ticket> {
    const tickets: Array<Ticket> = inputArray.map((input) => new Ticket().deserialize(input));

    return tickets;
  }

  clone(): Ticket {
    const clonedInstance: Ticket = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
