import { Serializable } from 'app/core/interfaces';

import { RightHolder, RightHolderDTO } from './rightHolder';

export interface UsageReportDTO {
  id?: string;
  name?: string;
  useType?: string;
  rightHolders?: RightHolderDTO[];
  usedWorks?: number;
}

export class UsageReport implements Serializable<UsageReport> {
  id?: string;
  name?: string;
  useType?: string;
  rightHolders?: RightHolder[];
  usedWorks?: number;

  deserialize(input: UsageReportDTO): UsageReport {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.useType = input.useType || '';
    this.rightHolders = input.rightHolders
      ? new RightHolder().deserializeArray(input.rightHolders)
      : [];
    this.usedWorks = input.usedWorks || 0;

    return this;
  }

  deserializeArray(inputArray: Array<UsageReportDTO>): Array<UsageReport> {
    const usageReports: Array<UsageReport> = inputArray.map((input) =>
      new UsageReport().deserialize(input),
    );

    return usageReports;
  }

  clone(): UsageReport {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: UsageReport = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
