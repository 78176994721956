/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Catalog, CatalogDTO } from './catalog';
import { Work, WorkDTO } from './work';
import { RightHolder, RightHolderDTO } from './rightHolder';
import { CollectionShare, CollectionShareDTO } from './collectionShare';
import { OwnershipShare, OwnershipShareDTO } from './ownershipShare';

export interface ShareDTO {
  id: string;
  catalog: CatalogDTO;
  catalogId: string;
  composition: WorkDTO;
  compositionId: string;
  work: WorkDTO;
  workId: string;
  createdAt: string;
  parentId: string;
  rightHolder: RightHolderDTO;
  rightHolderId: string;
  role: string;
  societyAssignedAgreementNumber: string;
  submitterAgreementNumber: string;
  updatedAt: string;
  parent: ShareDTO;
  sons: Array<ShareDTO>;
  collectionShareId: string;
  collectionShare: CollectionShareDTO;
  ownershipShareId: string;
  ownershipShare: OwnershipShareDTO;
}

export class Share implements Serializable<Share> {
  id: string;
  catalog: Catalog;
  catalogId: string;
  work: Work;
  workId: string;
  createdAt: string;
  parentId: string;
  rightHolder: RightHolder;
  rightHolderId: string;
  role: string;
  societyAssignedAgreementNumber: string;
  submitterAgreementNumber: string;
  updatedAt: string;
  parent: Share;
  sons: Array<Share>;
  collectionShareId: string;
  collectionShare: CollectionShare;
  ownershipShareId: string;
  ownershipShare: OwnershipShare;

  deserialize(input: ShareDTO): Share {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.catalog = input.catalog ? new Catalog().deserialize(input.catalog) : null;
    this.catalogId = input.catalogId || '';
    this.workId = input.workId || input.compositionId || '';

    this.createdAt = input.createdAt || '';
    this.parentId = input.parentId || '';
    this.rightHolder = input.rightHolder ? new RightHolder().deserialize(input.rightHolder) : null;
    this.rightHolderId = input.rightHolderId || '';
    this.role = input.role || '';
    this.societyAssignedAgreementNumber = input.societyAssignedAgreementNumber || '';
    this.submitterAgreementNumber = input.submitterAgreementNumber || '';
    this.updatedAt = input.updatedAt || '';
    this.parent = input.parent ? new Share().deserialize(input.parent) : null;

    this.collectionShareId = input.collectionShareId || '';
    this.collectionShare = input.collectionShare
      ? new CollectionShare().deserialize(input.collectionShare)
      : null;
    this.ownershipShareId = input.ownershipShareId || '';
    this.ownershipShare = input.ownershipShare
      ? new OwnershipShare().deserialize(input.ownershipShare)
      : null;

    this.work = null;
    if (input.composition) {
      this.work = new Work().deserialize(input.composition);
    }

    if (input.work) {
      this.work = new Work().deserialize(input.work);
    }

    return this;
  }

  get isOwnershipShare(): boolean {
    return this.ownershipShare !== null;
  }

  get isCollectionShare(): boolean {
    return this.collectionShare !== null;
  }

  deserializeArray(inputArray: Array<ShareDTO>): Array<Share> {
    const shares: Array<Share> = inputArray.map((input) => new Share().deserialize(input));

    return shares;
  }

  clone(): Share {
    const clonedInstance: Share = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
