import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
// import { Work, WorkDTO } from './work';
// import { RecordingUsage } from './recordingUsage';
import { Performer, PerformerDTO } from './performer';
// eslint-disable-next-line import/no-cycle
import { Work, WorkDTO } from './work';

export interface RecordingDTO {
  id: string;
  unisonId: string;
  firstReleaseDate: string;
  firstReleaseDuration: number;
  firstAlbumTitle: string;
  firstAlbumLabel: string;
  firstReleaseCatalogNumber: number;
  ean: string;
  recordingFormat: string;
  recordingTechnique: string;
  mediaType: string;
  isrc: string;
  title: string;
  composition: WorkDTO;
  work?: WorkDTO;
  compositionId: string;
  // recordingUsages: RecordingUsage[];
  performers: PerformerDTO[];
  // updatedAt: string;
  // createdAt: string;
}
export class Recording implements Serializable<Recording> {
  id: string;
  unisonId: string;
  firstReleaseDate: string;
  firstReleaseDuration: number;
  firstAlbumTitle: string;
  firstAlbumLabel: string;
  firstReleaseCatalogNumber: number;
  ean: string;
  recordingFormat: string;
  recordingTechnique: string;
  mediaType: string;
  isrc: string;
  title: string;
  work: Work;
  workId: string;
  // recordingUsages: RecordingUsage[];
  performers: Performer[];
  // updatedAt: string;
  // createdAt: string;

  deserialize(input: RecordingDTO): Recording {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.firstReleaseDate = input.firstReleaseDate || '';
    this.firstReleaseDuration = input.firstReleaseDuration || 0;
    this.firstAlbumTitle = input.firstAlbumTitle || '';
    this.firstAlbumLabel = input.firstAlbumLabel || '';
    this.firstReleaseCatalogNumber = input.firstReleaseCatalogNumber || 0;
    this.ean = input.ean || '';
    this.recordingFormat = input.recordingFormat || '';
    this.mediaType = input.mediaType || '';
    this.isrc = input.isrc || '';
    this.title = input.title || '';
    // this.work = input.work ? new Work().deserialize(input.work) : null;
    this.workId = input.compositionId || '';
    // this.recordingUsages = input.recordingUsages || '';
    this.performers = input.performers ? new Performer().deserializeArray(input.performers) : [];
    // this.updatedAt = input.updatedAt || '';
    // this.createdAt = input.createdAt || '';

    if (input.composition) {
      this.work = input.composition ? new Work().deserialize(input.composition) : null;
    }

    if (input.work) {
      this.work = input.work ? new Work().deserialize(input.work) : null;
    }

    return this;
  }

  deserializeArray(inputArray: Array<RecordingDTO>): Array<Recording> {
    const recordings: Array<Recording> = inputArray.map((input) =>
      new Recording().deserialize(input),
    );

    return recordings;
  }

  clone(): Recording {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: Recording = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }

  get performersNames(): string {
    const { performers } = this;

    const rightHoldersNames: Array<string> = performers.map(
      (performer) => `${performer.firstName} ${performer.lastName}`,
    );

    return rightHoldersNames.join(' & ');
  }
}
