import { Serializable } from 'app/core/interfaces';

export interface TerritoryDTO {
  tis?: number;
  name?: string;
  // territories?: TerritoryDTO[];
  // parents?: TerritoryDTO[];
  // updatedAt?: string;
  // createdAt?: string;
}

export class Territory implements Serializable<Territory> {
  tis?: number;
  name?: string;
  // territories?: Territory[];
  // parents?: Territory[];
  // updatedAt?: string;
  // createdAt?: string;

  deserialize(input: TerritoryDTO): Territory {
    if (!input) {
      return this;
    }

    this.tis = input.tis;
    this.name = input.name;
    // this.territories = input.territories ? new Territory().deserializeArray(input.territories) : [];
    // this.parents = input.parents ? new Territory().deserializeArray(input.parents) : [];
    // this.updatedAt = input.updatedAt;
    // this.createdAt = input.createdAt;

    return this;
  }

  deserializeArray(inputArray: Array<TerritoryDTO>): Array<Territory> {
    const territories: Array<Territory> = inputArray.map((input) =>
      new Territory().deserialize(input),
    );

    return territories;
  }

  clone(): Territory {
    const clonedInstance: Territory = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
