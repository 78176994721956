export function toFormData<T>(formValue: T): FormData {
  const formData = new FormData();

  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];

    formData.append(key, value);
  });

  return formData;
}
