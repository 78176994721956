import { Injectable } from '@angular/core';

import { RightHolder, Territory } from 'app/shared/models';
import { CountryService } from './country.service';

interface CsvRightHolder {
  first_name?: string;
  surname?: string;
  name?: string;
  mo_share?: number;
  po_share?: number;
  mc_share?: number;
  pc_share?: number;
  share?: number;
  controlled?: boolean;
  cae_no?: string;
  unison_id?: string;
  capacity?: string;
  affiliation?: string;
  linked_publisher?: string;
  linked_publishers: string[];
  territories: string[];
}

interface CsvClient {
  name?: string;
  share?: number;
}

interface CsvRecording {
  release_date?: string;
  album_title?: string;
  record_label?: string;
  catalog_number?: string;
  isrc?: string;
  title?: string;
  artist?: string;
  unison_id?: string;
}

export interface CsvSong {
  line: number;
  title?: string;
  code?: string;
  iswc?: string;
  notes?: string;
  copyright_date?: string;
  aka: string[];
  composers: Array<CsvRightHolder>;
  publishers: Array<CsvRightHolder>;
  clients: Array<CsvClient>;
  territories: string[];
  recording?: CsvRecording;
  recorded?: boolean;
  recordings: CsvRecording[];
  artist?: string;
  custom_text_field_1?: string;
  custom_text_field_2?: string;
  custom_text_field_3?: string;
  custom_text_field_4?: string;
}

export interface CsvError {
  message: string;
  song: CsvSong;
  rightHolders?: any[];
  oldValue?: string;
}

@Injectable()
export class CsvParserService {
  csvJSON: any;
  errors: Array<CsvError>;
  file: any;
  rows: string[];
  headers: string[];
  separator: string;
  currentRow: string[];
  song: CsvSong;
  catalogs: any[];
  rightHolders: any;
  ipis: any;
  societies: any;
  territories: Array<Territory>;
  performers: any;

  constructor(private countryService: CountryService) {}

  static valueInArrayObjects(value: any, array: any[]): boolean {
    for (let i = 0; i < array.length; i += 1) {
      if (value.toUpperCase() === array[i].name) {
        return true;
      }
    }
    return false;
  }

  async createJSONFromFile(files: FileList, chunk: number): Promise<any> {
    if (!files || files.length <= 0) {
      return false;
    }

    const territories = await this.countryService.getTerritories();
    this.territories = territories;

    const file: File = files.item(0);

    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        this.file = reader.result;
        try {
          this.parseFile();
          this.splitCompositions(chunk);
          resolve({
            csvJSON: this.csvJSON,
            errors: this.errors,
            catalogs: this.catalogs,
            rightHolders: Object.values(this.rightHolders),
            societies: Object.values(this.societies),
            performers: Object.values(this.performers),
          });
        } catch (e) {
          reject(e);
        }
      };
      reader.onerror = () => reject();
    });
  }

  splitCompositions(chunk = 1): void {
    this.catalogs = [];
    for (let i = 0; i < this.csvJSON.songs.length; i += chunk) {
      const csv = { songs: [] };
      for (let j = 0; j < chunk; j += 1) {
        if (i + j < this.csvJSON.songs.length) {
          csv.songs.push(this.csvJSON.songs[i + j]);
        }
      }
      this.catalogs.push(csv);
    }
  }

  parseFile(): void {
    let header = null;
    [header, ...this.rows] = this.file.split('\n');
    this.separator = header.indexOf(';') === -1 ? ',' : ';';
    this.headers = header.split(this.separator);
    this.errors = [];
    this.rightHolders = {};
    this.ipis = {};
    this.societies = {};
    this.performers = {};
    this.csvJSON = { songs: [] };
    this.parseByRows();
  }

  parseByRows(): void {
    for (let i = 0; i < this.rows.length; i += 1) {
      this.currentRow = this.rows[i].split(this.separator);
      this.song = {
        aka: [],
        composers: [],
        publishers: [],
        clients: [],
        territories: [],
        recordings: [],
        recording: {},
        line: i + 1,
      };

      // Fuse fields when necessary
      while (this.fuse1Field());

      for (let j = 0; j < this.currentRow.length && j < this.headers.length; j += 1) {
        const header: string = this.headers[j].toLowerCase();
        const number: number = this.currentRow[j]
          ? parseFloat(this.currentRow[j].replace(/,/g, '.'))
          : 0;
        const boolean: boolean = this.currentRow[j] ? this.currentRow[j] === 'Y' : true;
        const string: string = this.currentRow[j].trim();

        if (header === 'song code') {
          this.song.code = string;
        } else if (header === 'song title') {
          this.song.title = string;
        } else if (header === 'iswc') {
          this.song.iswc = string;
        } else if (header === 'song notes') {
          this.song.notes = string;
        } else if (header === 'copyright date') {
          this.song.copyright_date = string;
        } else if (header.indexOf('aka') !== -1) {
          if (string !== '') {
            this.song.aka.push(string);
          }
        } else if (header.indexOf('composer') !== -1) {
          const composerN = parseInt(header.substr(9, 2).trim(), 10) - 1;
          if (this.song.composers.length < composerN + 1 && string !== '') {
            this.song.composers.push({ territories: [], linked_publishers: [] });
          }

          if (
            string !== '' ||
            (string === '' &&
              header.indexOf('controlled') !== -1 &&
              this.song.composers.length < composerN + 1)
          ) {
            if (header.indexOf('first name') !== -1) {
              this.song.composers[composerN].first_name = string;
            } else if (header.indexOf('surname') !== -1) {
              this.song.composers[composerN].surname = string;
            } else if (header.indexOf('name') !== -1) {
              this.song.composers[composerN].name = string;
            } else if (header.indexOf('mo share') !== -1) {
              this.song.composers[composerN].mo_share = number;
            } else if (header.indexOf('po share') !== -1) {
              this.song.composers[composerN].po_share = number;
            } else if (header.indexOf('mc share') !== -1) {
              this.song.composers[composerN].mc_share = number;
            } else if (header.indexOf('pc share') !== -1) {
              this.song.composers[composerN].pc_share = number;
            } else if (header.indexOf('share') !== -1) {
              this.song.composers[composerN].share = number;
            } else if (header.indexOf('controlled') !== -1) {
              this.song.composers[composerN].controlled = boolean;
            } else if (header.indexOf('cae') !== -1) {
              this.song.composers[composerN].cae_no = string;
            } else if (header.indexOf('unison id') !== -1) {
              this.song.composers[composerN].unison_id = string;
            } else if (header.indexOf('capacity') !== -1) {
              this.song.composers[composerN].capacity = string;
            } else if (header.indexOf('affiliation') !== -1) {
              this.song.composers[composerN].affiliation = string;
              this.addSociety(string);
            } else if (header.indexOf('linked publisher') !== -1) {
              this.song.composers[composerN].linked_publisher = string;
              this.song.composers[composerN].linked_publishers.push(string);
            } else if (header.indexOf('territory')) {
              if (
                CsvParserService.valueInArrayObjects(string, this.territories) &&
                this.song.composers[composerN].territories.indexOf(string) === -1
              ) {
                this.song.composers[composerN].territories.push(string);
              } else if (!CsvParserService.valueInArrayObjects(string, this.territories)) {
                this.errors.push({
                  message:
                    'Territory is not in our database and has not been added. Default territory will be used.',
                  song: this.song,
                  oldValue: string,
                });
              }
            } else {
              this.log(header);
            }
          }
        } else if (header.indexOf('publisher') !== -1) {
          const publisherN = parseInt(header.substr(10, 2).trim(), 10) - 1;
          if (this.song.publishers.length < publisherN + 1 && string !== '') {
            this.song.publishers.push({ territories: [], linked_publishers: [] });
          }

          if (
            string !== '' ||
            (string === '' &&
              header.indexOf('controlled') !== -1 &&
              this.song.publishers.length < publisherN + 1)
          ) {
            if (header.indexOf('name') !== -1) {
              this.song.publishers[publisherN].name = string;
            } else if (header.indexOf('mo share') !== -1) {
              this.song.publishers[publisherN].mo_share = number;
            } else if (header.indexOf('po share') !== -1) {
              this.song.publishers[publisherN].po_share = number;
            } else if (header.indexOf('mc share') !== -1) {
              this.song.publishers[publisherN].mc_share = number;
            } else if (header.indexOf('pc share') !== -1) {
              this.song.publishers[publisherN].pc_share = number;
            } else if (header.indexOf('share') !== -1) {
              this.song.publishers[publisherN].share = number;
            } else if (header.indexOf('controlled') !== -1) {
              this.song.publishers[publisherN].controlled = boolean;
            } else if (header.indexOf('cae') !== -1) {
              this.song.publishers[publisherN].cae_no = string;
            } else if (header.indexOf('unison id') !== -1) {
              this.song.publishers[publisherN].unison_id = string;
            } else if (header.indexOf('capacity') !== -1) {
              this.song.publishers[publisherN].capacity = string;
            } else if (header.indexOf('affiliation') !== -1) {
              this.song.publishers[publisherN].affiliation = string;
              this.addSociety(string);
            } else if (header.indexOf('linked publisher') !== -1) {
              this.song.publishers[publisherN].linked_publisher = string;
              this.song.publishers[publisherN].linked_publishers.push(string);
            } else if (header.indexOf('territory')) {
              if (
                CsvParserService.valueInArrayObjects(string, this.territories) &&
                this.song.publishers[publisherN].territories.indexOf(string) === -1
              ) {
                this.song.publishers[publisherN].territories.push(string);
              } else if (!CsvParserService.valueInArrayObjects(string, this.territories)) {
                this.errors.push({
                  message:
                    'Territory is not in our database and has not been added. Default territory will be used.',
                  song: this.song,
                  oldValue: string,
                });
              }
            } else {
              this.log(header);
            }
          }
        } else if (header.indexOf('client') !== -1) {
          const clientN = parseInt(header.substr(10, 2).trim(), 10) - 1;
          if (this.song.clients.length < clientN + 1 && string !== '') {
            this.song.clients.push({});
          }

          if (string !== '') {
            if (header.indexOf('name') !== -1) {
              this.song.clients[clientN].name = string;
            } else if (header.indexOf('share') !== -1) {
              this.song.clients[clientN].share = number;
            } else {
              this.log(header);
            }
          }
        } else if (header.indexOf('territory') !== -1) {
          if (
            CsvParserService.valueInArrayObjects(string, this.territories) &&
            this.song.territories.indexOf(string) === -1
          ) {
            this.song.territories.push(string);
          } else if (!CsvParserService.valueInArrayObjects(string, this.territories)) {
            this.errors.push({
              message:
                'Territory is not in our database and has not been added. Default territory will be used.',
              song: this.song,
              oldValue: string,
            });
          }
        } else if (header.indexOf('release date') !== -1) {
          this.song.recording.release_date = string;
        } else if (header.indexOf('album title') !== -1) {
          this.song.recording.album_title = string;
        } else if (header.indexOf('record label') !== -1) {
          this.song.recording.record_label = string;
        } else if (header.indexOf('catalog number') !== -1) {
          this.song.recording.catalog_number = string;
        } else if (header === 'isrc') {
          this.song.recording.isrc = string;
        } else if (header.indexOf('recorded') !== -1) {
          this.song.recorded = boolean;
        } else if (header === 'custom text field 1') {
          this.song.custom_text_field_1 = string;
        } else if (header === 'custom text field 2') {
          this.song.custom_text_field_2 = string;
        } else if (header === 'custom text field 3') {
          this.song.custom_text_field_3 = string;
        } else if (header === 'custom text field 4') {
          this.song.custom_text_field_4 = string;
        } else if (header.indexOf('recording') !== -1) {
          const recordingN = parseInt(header.substr(10, 2).trim(), 10) - 1;
          if (this.song.recordings.length < recordingN + 1 && string !== '') {
            this.song.recordings.push({});
          }

          if (string !== '') {
            if (header.indexOf('title') !== -1) {
              this.song.recordings[recordingN].title = string;
            } else if (header.indexOf('artist') !== -1) {
              this.song.recordings[recordingN].artist = string;
            } else if (header.indexOf('isrc') !== -1) {
              this.song.recordings[recordingN].isrc = string;
            } else if (header.indexOf('unison id') !== -1) {
              this.song.recordings[recordingN].unison_id = string;
            }
          }
        } else if (header.indexOf('artist') !== -1) {
          this.song.artist = string;
        } else {
          this.log(header);
        }
      }
      this.cleanEmptyRightHolders();
      if (this.song.title) {
        this.csvJSON.songs.push(this.song);
        this.addSongRightHolders();
      } else if (this.currentRow.reduce((ant, act) => ant + act, '').trim().length > 0) {
        this.errors.push({
          message: 'Song has not been added for lack of title',
          song: this.song,
        });
      }
    }
  }

  addSongRightHolders(): void {
    // Check publishers
    for (let i = 0; i < this.song.publishers.length; i += 1) {
      const pub = this.song.publishers[i];
      if (!(pub.name in this.rightHolders)) {
        this.addRightHolder(pub);
      }
    }

    // Check composers and pwrs
    for (let i = 0; i < this.song.composers.length; i += 1) {
      const comp = this.song.composers[i];
      if (!(comp.name in this.rightHolders)) {
        this.addRightHolder(comp);
      }
      for (let j = 0; j < comp.linked_publishers.length; j += 1) {
        const pwr = comp.linked_publishers[j];
        if (!(pwr in this.rightHolders)) {
          this.addRightHolder({ name: pwr });
        }
      }
    }
  }

  addRightHolder(rightHolder: any): void {
    let ipi;
    let addRightHolder = true;

    if (rightHolder.cae_no) {
      ipi = parseInt(rightHolder.cae_no.replace(/\./g, ''), 10);
      if (!Number.isNaN(ipi) && ipi > 0) {
        if (!(ipi in this.ipis)) {
          this.ipis[ipi] = ipi;
        } else {
          addRightHolder = false;
        }
      }
    }

    if (addRightHolder) {
      if (!this.rightHolders[rightHolder.name]) {
        this.rightHolders[rightHolder.name] = new RightHolder();
        this.rightHolders[rightHolder.name].name = rightHolder.name;
      }

      if (
        ipi &&
        this.rightHolders[rightHolder.name].ipi &&
        this.rightHolders[rightHolder.name].ipi !== ipi
      ) {
        this.rightHolders[`${rightHolder.name}${ipi}`] = new RightHolder();
        this.rightHolders[`${rightHolder.name}${ipi}`].name = rightHolder.name;
      } else if (ipi) {
        this.rightHolders[rightHolder.name].ipi = ipi;
      }
    } else {
      // this.log(rightHolder);
    }
  }

  addSociety(name: string): void {
    if (!(name in this.societies)) {
      this.societies[name] = { name };
    }
  }

  addPerformer(lastName: string): void {
    this.performers[lastName] = { lastName };
  }

  fuse1Field(): boolean {
    if (this.currentRow.length <= this.headers.length) {
      return false;
    }

    let index1: number;
    let index2: number;

    for (let i = 0; i < this.currentRow.length && !index2; i += 1) {
      if (this.currentRow[i].indexOf('"') !== -1 && index1 === undefined) {
        index1 = i;
      } else if (this.currentRow[i].indexOf('"') !== -1) {
        index2 = i;
      }
    }

    if (index1 === undefined || index2 === undefined) {
      return false;
    }

    const firstSlice = this.currentRow.slice(0, index1);
    const lastSlice = this.currentRow.slice(index2 + 1);
    const fusedField = this.currentRow.slice(index1, index2 + 1).join(this.separator);
    this.currentRow = [...firstSlice, this.cleanFieldStr(fusedField), ...lastSlice];
    return true;
  }

  cleanFieldStr(field: string): string {
    let newField = field;
    newField = newField.replace(/"/g, '');
    newField = newField.replace(/&AMP;/g, '&');
    newField = newField.replace(/&ACUTE;/g, "'");

    return newField;
  }

  cleanEmptyRightHolders(): void {
    for (let i = 0; i < this.song.publishers.length; i += 1) {
      const publisher = this.song.publishers[i];
      if (!publisher.name && !publisher.capacity && !publisher.cae_no) {
        this.song.publishers.splice(i, 1);
        i -= 1;
      }
    }
    for (let i = 0; i < this.song.composers.length; i += 1) {
      const composer = this.song.composers[i];
      if (!composer.name && !composer.capacity && !composer.cae_no) {
        this.song.composers.splice(i, 1);
        i -= 1;
      }
    }
  }

  log(message: any): void {
    console.log(message);
  }
}
