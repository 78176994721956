/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Liquidation, LiquidationDTO } from './liquidation';
import { Catalog, CatalogDTO } from './catalog';
import { UsageTypes } from './usage';
import { Share, ShareDTO } from './share';

export interface CollectionShareLiquidationDTO {
  id?: string;
  amount?: number;
  liquidationId?: string;
  liquidation?: LiquidationDTO;
  dateFrom: string;
  dateTo: string;
  type?: UsageTypes;
  catalogId?: string;
  catalog?: CatalogDTO;
  createdAt?: string;
  updatedAt?: string;
  shareId: string;
  share: ShareDTO;
}

export class CollectionShareLiquidation implements Serializable<CollectionShareLiquidation> {
  id?: string;
  amount?: number;
  liquidationId?: string;
  liquidation?: Liquidation;
  dateFrom: string;
  dateTo: string;
  type?: UsageTypes;
  catalog?: Catalog;
  catalogId?: string;
  createdAt?: string;
  updatedAt?: string;
  shareId: string;
  share: Share;

  deserialize(input: CollectionShareLiquidationDTO): CollectionShareLiquidation {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.amount = input.amount;
    this.type = input.type;
    this.liquidation = input.liquidation ? new Liquidation().deserialize(input.liquidation) : null;
    this.liquidationId = input.liquidationId;
    this.catalogId = input.catalogId;
    this.catalog = input.catalog ? new Catalog().deserialize(input.catalog) : null;
    this.dateFrom = input.dateFrom;
    this.dateTo = input.dateTo;
    this.updatedAt = input.updatedAt;
    this.createdAt = input.createdAt;
    this.shareId = input.shareId || '';
    this.share = input.share ? new Share().deserialize(input.share) : null;

    return this;
  }

  deserializeArray(
    inputArray: Array<CollectionShareLiquidationDTO>,
  ): Array<CollectionShareLiquidation> {
    const collectionShareLiquidations: Array<CollectionShareLiquidation> = inputArray.map((input) =>
      new CollectionShareLiquidation().deserialize(input),
    );

    return collectionShareLiquidations;
  }

  clone(): CollectionShareLiquidation {
    const clonedInstance: CollectionShareLiquidation = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
